/**
 * @generated SignedSource<<d0f90a198a10b2aad0e2657a13955edb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type PipelineJobYamlCodeViewerModalQuery$variables = {
  id: string;
  override?: boolean | null;
};
export type PipelineJobYamlCodeViewerModalQuery$data = {
  readonly pipelineJob: {
    readonly yaml: string;
  } | null;
};
export type PipelineJobYamlCodeViewerModalQuery = {
  response: PipelineJobYamlCodeViewerModalQuery$data;
  variables: PipelineJobYamlCodeViewerModalQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "override"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "override",
      "variableName": "override"
    }
  ],
  "kind": "ScalarField",
  "name": "yaml",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PipelineJobYamlCodeViewerModalQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "PipelineJob",
        "kind": "LinkedField",
        "name": "pipelineJob",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PipelineJobYamlCodeViewerModalQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "PipelineJob",
        "kind": "LinkedField",
        "name": "pipelineJob",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6b62a27e32addce39de6205a2ea18a9a",
    "id": null,
    "metadata": {},
    "name": "PipelineJobYamlCodeViewerModalQuery",
    "operationKind": "query",
    "text": "query PipelineJobYamlCodeViewerModalQuery(\n  $id: ID!\n  $override: Boolean\n) {\n  pipelineJob(id: $id) {\n    yaml(override: $override)\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "39dbbfb2dade7e1f4b2d7d6becf6fda5";

export default node;
