import Button from "antd/es/button";
import Divider from "antd/es/divider";
import Form, { FormItemProps, FormListProps, Rule } from "antd/es/form";
import Input, { InputRef } from "antd/es/input/Input";
import theme from "antd/lib/theme";
import _ from "lodash";
import {
  LucideBadgeAlert,
  LucideBadgeCheck,
  LucideCircleMinus,
  LucidePlus,
  LucideRotateCcw,
} from "lucide-react";
import React, { useRef } from "react";

import Flex from "./Flex";

export interface IEnvironmentVariableMap {
  [key: string]: string;
}

export interface IEnvironmentVariable {
  key: string;
  value: string;
}

export const getEnvironmentVariableArray = (
  environmentVariableMap?: IEnvironmentVariableMap
): IEnvironmentVariable[] =>
  _.entries(environmentVariableMap).map(([key, value]) => ({ key, value }));

export const getEnvironmentVariableMap = (
  environmentVariableArray?: IEnvironmentVariable[]
): IEnvironmentVariableMap =>
  _.reduce(
    environmentVariableArray,
    (map, env) => ({ ...map, [env.key]: env.value }),
    {}
  );

export const KeyRules: { [key: string]: Rule } = {
  BLOCK_BACKENDAI_PREFIX: {
    pattern: /^(?!BACKENDAI_).+/,
    message: "Keys starting with 'BACKENDAI_' are not allowed.",
  },
  BLOCK_HPC_OPTIONS: {
    pattern: /^(?!^(OMP|OPENBLAS)_NUM_THREADS$)/,
    message: "HPC options (e.g. OpenMP/OpenBLAS) are already reserved.",
  },
};

interface Props extends Omit<FormListProps, "children"> {
  formItemProps?: FormItemProps;
  keyRules?: Rule[];
  disabled?: boolean;
  inheritedEnvironmentVariables?: IEnvironmentVariable[];
}

const EnvironmentVariableList: React.FC<Props> = ({
  formItemProps,
  keyRules = [],
  inheritedEnvironmentVariables = [],
  ...props
}) => {
  const { token } = theme.useToken();
  const form = Form.useFormInstance();
  const inputRef = useRef<InputRef>(null);
  const placeholders = {
    key: "Name",
  };
  return (
    <Form.List {...props}>
      {(fields, { add, remove }) => (
        <Flex direction="column" align="stretch" gap={"xs"}>
          {fields.map(({ key, name, ...restFields }, index) => {
            const isInherited = index < inheritedEnvironmentVariables.length;
            return (
              <>
                <Flex key={key} direction="row" align="baseline" gap={"xs"}>
                  <Form.Item
                    {...restFields}
                    style={{ marginBottom: 0, flex: 1 }}
                    shouldUpdate
                  >
                    {({ getFieldValue }) => {
                      const isValueDirty =
                        getFieldValue([props.name, name, "value"]) !==
                        inheritedEnvironmentVariables[index]?.value;
                      return (
                        <Form.Item
                          name={[name, "key"]}
                          style={{ marginBottom: 0, flex: 1 }}
                          rules={[
                            {
                              required: true,
                              message: `${placeholders.key} is required.`,
                            },
                            {
                              pattern: /^[a-zA-Z_][a-zA-Z0-9_]*$/,
                              message: `${placeholders.key} must start with an alphabet letter or an underscore, and can only contain letters, numbers, and underscores.`,
                            },
                            ({ getFieldValue }) => ({
                              validator(rule, value) {
                                if (
                                  !_.isEmpty(value) &&
                                  _.filter(
                                    getFieldValue(
                                      props.name
                                    ) as IEnvironmentVariable[],
                                    (__, i) => i !== index
                                  ).find((env) => env.key === value)
                                ) {
                                  return Promise.reject(
                                    `${placeholders.key} already exists.`
                                  );
                                }
                                return Promise.resolve();
                              },
                            }),
                            ...keyRules,
                          ]}
                          {...formItemProps}
                        >
                          <Input
                            ref={index === fields.length - 1 ? inputRef : null}
                            placeholder={placeholders.key}
                            prefix={
                              isInherited &&
                              (isValueDirty ? (
                                <LucideBadgeAlert color={token.colorWarning} />
                              ) : (
                                <LucideBadgeCheck color={token.colorSuccess} />
                              ))
                            }
                            onChange={() => {
                              const fieldNames = fields.map((field, index) => [
                                ..._.castArray(props.name),
                                index,
                                "key",
                              ]);
                              form.validateFields(fieldNames);
                            }}
                            disabled={props.disabled || isInherited}
                          />
                        </Form.Item>
                      );
                    }}
                  </Form.Item>
                  <Form.Item
                    {...restFields}
                    name={[name, "value"]}
                    style={{ marginBottom: 0, flex: 1 }}
                    rules={[{ required: true, message: "Value is required." }]}
                    validateTrigger={["onChange", "onBlur"]}
                  >
                    <Input placeholder="Value" disabled={props.disabled} />
                  </Form.Item>
                  {!props.disabled &&
                    (isInherited ? (
                      <Button
                        size={"small"}
                        type={"text"}
                        icon={
                          <LucideRotateCcw style={{ color: token.colorText }} />
                        }
                        onClick={() =>
                          form.setFieldValue(
                            [props.name, name, "value"],
                            inheritedEnvironmentVariables[index]?.value
                          )
                        }
                        tabIndex={-1}
                      />
                    ) : (
                      <Button
                        size={"small"}
                        type={"text"}
                        icon={
                          <LucideCircleMinus
                            style={{ color: token.colorError }}
                          />
                        }
                        onClick={() => remove(name)}
                        tabIndex={-1}
                      />
                    ))}
                </Flex>
                {index === inheritedEnvironmentVariables.length - 1 && (
                  <Divider
                    type={"horizontal"}
                    variant={"dashed"}
                    style={{ marginTop: 0, marginBottom: 0 }}
                  />
                )}
              </>
            );
          })}
          {!props.disabled && (
            <Button
              type="dashed"
              style={{ color: token.colorPrimary }}
              onClick={() => {
                add();
                setTimeout(() => {
                  inputRef.current?.focus();
                }, 0);
              }}
              icon={<LucidePlus />}
              block
            >
              Add
            </Button>
          )}
        </Flex>
      )}
    </Form.List>
  );
};

export default EnvironmentVariableList;
