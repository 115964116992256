import Divider from "antd/es/divider";
import Form from "antd/es/form";
import Select from "antd/es/select";
import Switch from "antd/es/switch";
import { useAtom } from "jotai";
import _ from "lodash";

import { Image, imagePresetOptionAtom } from "../hooks/atoms";
import { useImageGroupsState } from "../hooks/backendai";
import Flex from "./Flex";

const TaskEnvironmentFormInput = () => {
  const form = Form.useFormInstance();
  const [imageGroups, { getImageOptionKey }] = useImageGroupsState();
  const [isUsingImagePreset, setIsUsingImagePreset] = useAtom(
    imagePresetOptionAtom
  );
  return (
    <>
      <Form.Item
        name="environment"
        label={
          <Flex
            direction="row"
            align="center"
            justify="around"
            style={{ width: "100%" }}
          >
            Environment
            <Divider type={"vertical"} />
            <Flex direction="row" align="center" justify="end" gap={"xs"}>
              <Switch
                size={"small"}
                onChange={setIsUsingImagePreset}
                value={isUsingImagePreset}
              />
              Use preset
            </Flex>
          </Flex>
        }
        rules={[{ required: true }]}
      >
        <Select
          placeholder="Select an option and change input text above"
          onChange={(value) => {
            // reset version field when environment is changed
            const images: {
              [key: string]: Image[];
            } = _.reduce(
              imageGroups,
              (acc, subGroups) => {
                return {
                  ...acc,
                  ...subGroups,
                };
              },
              {}
            );
            const selectedImages = images[value];
            const selectedImage = form.getFieldValue("image");
            if (
              _.find(
                selectedImages,
                (i) => getImageOptionKey(i) === selectedImage
              ) === undefined
            ) {
              form.setFieldsValue({
                image: getImageOptionKey(selectedImages[0]),
              });
            }
          }}
          allowClear
          showSearch
          // WARN: `defaultValue` will not work on controlled Field. You should use `initialValues` of Form instead.
          // defaultValue={
          //   // auto select first option if there is only one option
          //   _.flatMap(imageGroups).length === 1
          //     ? _.first(_.keys(_.first(_.flatMap(imageGroups))))
          //     : undefined
          // }
        >
          {_.map(imageGroups, (subGroups, groupName) => {
            return (
              <Select.OptGroup label={groupName} key={groupName}>
                {_.map(subGroups, (images, subGroupName) => {
                  return (
                    <Select.Option key={subGroupName} value={subGroupName}>
                      {subGroupName}
                    </Select.Option>
                  );
                })}
              </Select.OptGroup>
            );
          })}
        </Select>
      </Form.Item>
      <Form.Item
        shouldUpdate={(prev, cur) => prev.environment !== cur.environment}
      >
        {({ getFieldValue }) => {
          const images = _.reduce(
            imageGroups,
            (acc, subGroups) => {
              return {
                ...acc,
                ...subGroups,
              };
            },
            {}
          );
          // eslint-disable-next-line
          //@ts-ignore
          const selectedImages = images[getFieldValue("environment")];
          return (
            <Form.Item
              name="image"
              label="Version"
              rules={[{ required: true }]}
            >
              <Select
                placeholder="Select a option and change input text above"
                onChange={() => {}}
                allowClear
                showSearch
              >
                {_.map(selectedImages, (image: any) => {
                  return (
                    <Select.Option key={getImageOptionKey(image)}>
                      {image.tag}
                      <Divider type="vertical" />
                      {image.architecture}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          );
        }}
      </Form.Item>
    </>
  );
};

export default TaskEnvironmentFormInput;
