import ConfigProvider from "antd/lib/config-provider";
// https://ant.design/docs/react/replace-moment#Custom-component
// https://github.com/ant-design/ant-design/issues/26190#issuecomment-703673400
import dayjs from "dayjs";
import "dayjs/locale/en";
import "dayjs/locale/ko";
import localeData from "dayjs/plugin/localeData";
import localizedFormat from "dayjs/plugin/localizedFormat";
import relativeTime from "dayjs/plugin/relativeTime";
import timezone from "dayjs/plugin/timezone";
import updateLocale from "dayjs/plugin/updateLocale";
import utc from "dayjs/plugin/utc";
import weekday from "dayjs/plugin/weekday";
import { Suspense, useState } from "react";
import { RelayEnvironmentProvider } from "react-relay";
import { Navigate, Route, Routes } from "react-router-dom";

import "./App.css";
import { createRelayEnvironment } from "./RelayEnvironment";
import FlexActivityIndicator from "./components/FlexActivityIndicator";
import MainLayout from "./components/MainLayout";
import OutdatedBrowserAlert from "./components/OutdatedBrowserAlert";
import RequireAuth from "./components/contexts/RequireAuth";
import "./fonts.css";
import { getLocale } from "./helpers/locale";
import { AuthProvider } from "./hooks/auth";
import Login from "./screens/Login";
import NoMatch from "./screens/NoMatch";
import PipelineJobDetail from "./screens/PipelineJobDetail";
import PipelineJobList from "./screens/PipelineJobList";
import PipelineList from "./screens/PipelineList";

dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(localizedFormat);
dayjs.extend(relativeTime);
dayjs.extend(updateLocale);
dayjs.extend(utc);
dayjs.extend(timezone);

dayjs.locale(getLocale());

// TODO: for the reset relay if needed, move this to a separate component with all the providers.
// const myEnvironmentKey = new EnvironmentKey("Backend.AI Environment");

const initialRelayEnvironment = createRelayEnvironment();
const App = () => {
  const [relayEnvironment, setRelayEnvironment] = useState(
    initialRelayEnvironment
  );

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#1677FF",
          colorPrimaryBg: "white",
          colorSuccess: "#37B076",
          colorText: "black",
          fontFamily: "Ubuntu",
          fontFamilyCode: "Ubuntu Mono",
        },
        components: {
          Tag: {
            borderRadiusSM: 1,
          },
          Collapse: {
            colorFillAlter: "#FAFAFA",
            borderRadiusLG: 0,
          },
        },
      }}
    >
      <OutdatedBrowserAlert />
      <RelayEnvironmentProvider environment={relayEnvironment}>
        <Suspense
          fallback={<FlexActivityIndicator style={{ height: "100vh" }} />}
        >
          <AuthProvider
            onLogin={() => {}}
            onLogout={() => {
              setRelayEnvironment(createRelayEnvironment());
            }}
            onInvalidToken={() => {
              setRelayEnvironment(createRelayEnvironment());
            }}
          >
            <Routes>
              <Route
                path="/"
                element={
                  <RequireAuth>
                    <MainLayout />
                  </RequireAuth>
                }
              >
                <Route
                  path="/"
                  element={<Navigate to="/pipelines" replace />}
                />
                <Route path="/pipelines/*" element={<PipelineList />} />
                <Route
                  path="/pipelines/:pipelineId"
                  element={<PipelineList />}
                />
                <Route path="/pipeline-jobs/*" element={<PipelineJobList />} />
                <Route
                  path="pipeline-jobs/:jobId"
                  element={<PipelineJobDetail />}
                />
              </Route>
              <Route path="login" element={<Login />} />
              <Route path="*" element={<NoMatch />} />
            </Routes>
          </AuthProvider>
        </Suspense>
      </RelayEnvironmentProvider>
    </ConfigProvider>
  );
};

export default App;
