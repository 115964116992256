import { EditOutlined, PlusOutlined, UploadOutlined } from "@ant-design/icons";
import Button from "antd/es/button";
import Form from "antd/es/form";
import Input from "antd/es/input";
import Modal, { ModalProps } from "antd/es/modal";
import Radio from "antd/es/radio";
import Select from "antd/es/select";
import Upload, { UploadProps } from "antd/es/upload";
import message from "antd/lib/message";
import type { RcFile, UploadFile } from "antd/lib/upload";
import graphql from "babel-plugin-relay/macro";
import { useAtomValue } from "jotai";
import { default as YAML } from "js-yaml";
import _ from "lodash";
import React, { Suspense, useEffect, useState } from "react";
import { useFragment, useLazyLoadQuery, useMutation } from "react-relay";
import SyntaxHighlighter from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/hljs";

import {
  extractNonNullableNodesOnConnection,
  from_global_id,
} from "../helpers";
import { hostListInfoAtom } from "../hooks/atoms";
import EnvironmentVariableList, {
  IEnvironmentVariable,
  KeyRules,
  getEnvironmentVariableArray,
  getEnvironmentVariableMap,
} from "./EnvironmentVariableList";
import Flex from "./Flex";
import { PipelineYAML } from "./PipelineYamlEditor";
import VirtualFolderHostSelect from "./VirtualFolderHostSelect";
import WhiteSpace from "./WhiteSpace";
import { PipelineEditorModalCreateMutation } from "./__generated__/PipelineEditorModalCreateMutation.graphql";
import { PipelineEditorModalFragment$key } from "./__generated__/PipelineEditorModalFragment.graphql";
import { PipelineEditorModalQuery } from "./__generated__/PipelineEditorModalQuery.graphql";
import { PipelineEditorModalUpdateMutation } from "./__generated__/PipelineEditorModalUpdateMutation.graphql";

const ProjectSelect = React.lazy(() => import("./ProjectSelect"));

interface PipelineCreatorModalProps extends ModalProps {
  onRequestClose: (pipelineId?: string, type?: "update" | "create") => void;
  pipelineFrgmt?: PipelineEditorModalFragment$key | null;
  connections?: readonly string[];
}

type PipelineInitializationType = "none" | "yaml" | "template";

type PipelineScope = "personal" | "project";

interface PipelineFormInput {
  name: string;
  description?: string;
  host: string;
  scope: PipelineScope;
  project: string;
  environment: {
    envs: IEnvironmentVariable[];
    project: string;
    "scaling-group": string;
  };
  initialization: PipelineInitializationType;
  template?: string;
}

const PipelineEditorModal: React.FC<PipelineCreatorModalProps> = ({
  onRequestClose,
  pipelineFrgmt = null,
  connections = [],
  ...props
}) => {
  const [form] = Form.useForm<PipelineFormInput>();

  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [pipelineYaml, setPipelineYaml] = useState<string | null>(null);
  const uploadProps: UploadProps = {
    fileList,
    accept: ".yml,.yaml",
    onChange({ file, fileList, event }) {
      if (file.status === "removed") {
        resetPipelineEditorInputs();
        return;
      }
      setFileList([file]);
      const reader = new FileReader();
      reader.addEventListener("load", (event) => {
        if (event.target?.result) {
          const [_, base64] = event.target.result.toString().split(","); // data:application/octet-stream;base64,ZW52aXJvb...
          setPipelineYaml(window.atob(base64));
        } else {
          setPipelineYaml(null);
        }
      });
      reader.readAsDataURL(file as RcFile);
    },
    beforeUpload(file) {
      return false;
    },
  };

  const resetPipelineEditorInputs = () => {
    form.resetFields();
    setPipelineYaml(null);
    setFileList([]);
    setPipelineScope("personal");
  };

  const hostListInfo = useAtomValue(hostListInfoAtom);
  const pipeline = useFragment(
    graphql`
      fragment PipelineEditorModalFragment on Pipeline {
        id
        name
        description
        storage
        yaml
        scheduledTask {
          id
        }
      }
    `,
    pipelineFrgmt
  );

  const [commitCreate, isInFlightCommitCreate] =
    useMutation<PipelineEditorModalCreateMutation>(graphql`
      mutation PipelineEditorModalCreateMutation(
        $input: CreatePipelineInput!
        $connections: [ID!]!
      ) {
        createPipeline(input: $input) {
          pipeline
            @prependNode(
              connections: $connections
              edgeTypeName: "PipelineEdge"
            ) {
            __typename
            ... on Pipeline {
              id
              name
              description
              lastModified
              storage
              yaml
            }
            ... on UnauthenticatedError {
              message
            }
          }
        }
      }
    `);
  const [commitUpdate, isInFlightCommitUpdate] =
    useMutation<PipelineEditorModalUpdateMutation>(graphql`
      mutation PipelineEditorModalUpdateMutation($input: UpdatePipelineInput!) {
        updatePipeline(input: $input) {
          pipeline {
            __typename
            ... on Pipeline {
              id
              name
              description
              lastModified
              storage
              yaml
            }
            ... on UnauthenticatedError {
              message
            }
          }
        }
      }
    `);

  const _onOk = (e: React.MouseEvent<HTMLElement>) => {
    form.validateFields().then((values) => {
      const envs = getEnvironmentVariableMap(values.environment.envs);
      if (pipeline) {
        let updateYaml = "";
        if (pipeline.yaml) {
          const yamlObj = YAML.load(pipeline.yaml || "") as PipelineYAML;
          yamlObj.name = values.name;
          updateYaml = YAML.dump(yamlObj, { noRefs: true });
        }
        commitUpdate({
          variables: {
            input: {
              id: pipeline.id,
              name: values.name,
              description: values?.description,
              environment: {
                envs: JSON.stringify(envs),
              },
              ...(pipeline.yaml ? { yaml: updateYaml } : {}),
            },
          },
          onCompleted(response) {
            switch (response.updatePipeline?.pipeline?.__typename) {
              case "Pipeline":
                onRequestClose(response.updatePipeline.pipeline.id, "update");
                break;
              case "UnauthenticatedError":
                message.error(response.updatePipeline.pipeline.message);
                break;
            }
          },
          onError(error) {
            message.error(error.message);
          },
        });
      } else {
        commitCreate({
          variables: {
            input: {
              name: values.name,
              description: values?.description,
              environment: {
                envs: JSON.stringify(envs),
              },
              scope: values.scope,
              ...(values.scope === "project"
                ? {
                    project: values.project,
                  }
                : {}),
              storage: {
                host: values.host,
              },
              initialization: values.initialization,
              ...(values.initialization === "yaml"
                ? { yaml: pipelineYaml ?? "" }
                : {}),
              ...(values.initialization === "template"
                ? { template: values.template }
                : {}),
            },
            connections,
          },
          updater(store) {
            for (const connectionID of connections) {
              const connection = store.get(connectionID);
              if (connection) {
                const totalCount = connection.getValue("totalCount");
                if (typeof totalCount === "number") {
                  connection.setValue(totalCount + 1, "totalCount");
                }
              }
            }
          },
          onCompleted(response, errors) {
            if (errors) {
              Modal.error({
                title: "Error",
                width: 800,
                content: (
                  <SyntaxHighlighter
                    language="json"
                    style={darcula}
                    wrapLongLines
                  >
                    {JSON.stringify(errors, null, 2)}
                  </SyntaxHighlighter>
                ),
              });
              return;
            }
            switch (response.createPipeline?.pipeline?.__typename) {
              case "Pipeline":
                resetPipelineEditorInputs();
                onRequestClose(response.createPipeline.pipeline.id, "create");
                break;
              case "UnauthenticatedError":
                message.error(response.createPipeline.pipeline.message);
                break;
            }
          },
        });
      }
    });
  };

  const pipelineTemplatesQuery = useLazyLoadQuery<PipelineEditorModalQuery>(
    graphql`
      query PipelineEditorModalQuery {
        pipelineTemplates {
          edges {
            node {
              id
              name
            }
          }
        }
      }
    `,
    {},
    {
      fetchPolicy: "store-and-network",
    }
  );

  const pipelineInitType = Form.useWatch("initialization", form);
  const pipelineTemplate = Form.useWatch("template", form);

  const pipelineTemplates = extractNonNullableNodesOnConnection(
    pipelineTemplatesQuery.pipelineTemplates
  );

  const initialYaml = YAML.load(pipeline?.yaml || "") as PipelineYAML;
  const initialEnvs: IEnvironmentVariable[] = getEnvironmentVariableArray(
    initialYaml?.environment?.envs
  );

  // NOTE: `form.resetFields()` does not restore `Form.List` by design.
  // - https://github.com/react-component/field-form/pull/622
  // - https://github.com/ant-design/ant-design/pull/45284
  useEffect(() => {
    form.setFieldValue(["environment", "envs"], initialEnvs);
  }, [initialYaml?.environment?.envs]);

  const pipelineProject = initialYaml?.ownership?.project
    ? [initialYaml.ownership.project]
    : undefined;

  const [pipelineScope, setPipelineScope] = useState<"personal" | "project">(
    initialYaml?.ownership?.scope ?? "personal"
  );

  return (
    <Modal
      title={
        pipeline ? (
          <>
            <EditOutlined /> Update Pipeline
          </>
        ) : (
          <>
            <PlusOutlined /> Create Pipeline
          </>
        )
      }
      footer={
        <Flex direction="row" justify="end">
          <Button
            onClick={() => {
              resetPipelineEditorInputs();
              onRequestClose();
            }}
          >
            Cancel
          </Button>
          <WhiteSpace direction="row" />
          <Button
            type="primary"
            onClick={_onOk}
            loading={isInFlightCommitCreate || isInFlightCommitUpdate}
            disabled={
              (pipelineInitType === "yaml" && pipelineYaml === null) ||
              (pipelineInitType === "template" && !pipelineTemplate)
            }
          >
            {pipeline ? "Save" : "Create"}
          </Button>
        </Flex>
      }
      onCancel={() => {
        resetPipelineEditorInputs();
        onRequestClose();
      }}
      {...props}
      destroyOnClose
      confirmLoading={isInFlightCommitCreate || isInFlightCommitUpdate}
      forceRender
    >
      <Form
        form={form}
        layout="vertical"
        preserve={false}
        initialValues={
          pipeline
            ? {
                id: from_global_id(pipeline.id)[1],
                name: pipeline?.name,
                description: pipeline?.description,
                scope: pipelineScope,
                project: pipelineProject,
                host: JSON.parse(pipeline.storage || "{}").host,
              }
            : {
                name: "",
                description: "",
                scope: "personal",
                host: hostListInfo.default,
              }
        }
        validateTrigger="onBlur"
      >
        {pipeline ? (
          <Form.Item name="id" label="Id">
            <Input disabled />
          </Form.Item>
        ) : null}

        <Form.Item
          name="name"
          label="Name"
          rules={[
            {
              required: true,
              message: "Please input the name of pipeline!",
            },
            {
              pattern: /^[a-zA-Z0-9][a-zA-Z0-9._-]{2,38}[a-zA-Z0-9]$/,
              message:
                "The pipeline name should have 4-40 alphabets/numbers without whitespaces.",
            },
          ]}
        >
          <Input
            showCount
            maxLength={40}
            onSelect={() => form.validateFields(["name"])}
            onChange={() => form.validateFields(["name"])}
          />
        </Form.Item>
        <Form.Item name="description" label="Description">
          <Input.TextArea />
        </Form.Item>
        <Flex direction="row" justify="between">
          <Form.Item
            name="scope"
            label="Pipeline Scope"
            rules={[{ required: true }]}
          >
            <Radio.Group
              options={[
                { label: "Personal", value: "personal" },
                { label: "Project", value: "project" },
              ]}
              value={pipelineScope}
              onChange={(e) => setPipelineScope(e.target.value)}
              disabled={pipeline !== null}
            />
          </Form.Item>
          {pipelineScope === "project" ? (
            <Form.Item
              style={{ marginBottom: 0, width: "60%" }}
              shouldUpdate={(previousValues, nextValues) => {
                return previousValues.project !== nextValues.project;
              }}
            >
              {({ setFieldValue }) => {
                return (
                  <Form.Item
                    name="project"
                    label="Project"
                    rules={[{ required: true }]}
                  >
                    <Suspense
                      fallback={
                        <Select placeholder="Loading..." loading disabled />
                      }
                    >
                      <ProjectSelect
                        onSelectChange={(value) =>
                          setFieldValue("project", value)
                        }
                        defaultValue={
                          pipeline !== null ? pipelineProject : undefined
                        }
                        disabled={pipeline !== null}
                      />
                    </Suspense>
                  </Form.Item>
                );
              }}
            </Form.Item>
          ) : null}
        </Flex>
        <Form.Item
          name="host"
          label="Storage Host"
          tooltip={
            pipeline
              ? "You cannot change the host of existing pipeline."
              : "A host of the virtual folder that will be created when the pipeline is created."
          }
          rules={[{ required: true }]}
        >
          <VirtualFolderHostSelect disabled={!!pipeline?.id} />
        </Form.Item>
        <Form.Item
          label="Environment Variables"
          tooltip="These environment variables will be assigned to each task within this pipeline. Each variable can be overridden by a task-level variable with the same name."
        >
          <EnvironmentVariableList
            name={["environment", "envs"]}
            keyRules={[
              KeyRules.BLOCK_BACKENDAI_PREFIX,
              KeyRules.BLOCK_HPC_OPTIONS,
            ]}
          />
        </Form.Item>
        {pipeline ? null : (
          <>
            <Form.Item
              name="initialization"
              label="Initialization Method"
              tooltip=""
              initialValue="none"
            >
              <Radio.Group
                options={[
                  { label: "None", value: "none" },
                  { label: "YAML", value: "yaml" },
                  { label: "Template", value: "template" },
                ]}
                optionType="button"
              />
            </Form.Item>
            <Form.Item
              label="Pipeline YAML"
              hidden={pipelineInitType !== "yaml"}
            >
              <Upload {...uploadProps}>
                <Button icon={<UploadOutlined />}>Upload</Button>
              </Upload>
            </Form.Item>
            <Form.Item
              name="template"
              label="Pipeline Template"
              initialValue={pipelineTemplate || null}
              hidden={pipelineInitType !== "template"}
            >
              <Select
                placeholder="Select a pipeline template"
                showSearch
                options={pipelineTemplates.map((template) => {
                  return {
                    value: template.id,
                    label: template.name,
                  };
                })}
              />
            </Form.Item>
          </>
        )}
        {/* <Form.Item
          name="modifier"
          className="collection-create-form_last-form-item"
        >
          <Radio.Group>
            <Radio value="public">Public</Radio>
            <Radio value="private">Private</Radio>
          </Radio.Group>
        </Form.Item> */}
      </Form>
    </Modal>
  );
};

export default PipelineEditorModal;
