/**
 * @generated SignedSource<<5e5eb33318e7f4ba0e5e6f75c2cc1d57>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ClonePipelineJobInput = {
  clientMutationId?: string | null;
  envs?: any | null;
  id: string;
  name?: string | null;
};
export type PipelineJobCloneButtonMutation$variables = {
  input: ClonePipelineJobInput;
};
export type PipelineJobCloneButtonMutation$data = {
  readonly clonePipelineJob: {
    readonly pipelineJob: {
      readonly __typename: "PipelineJob";
      readonly id: string;
    } | {
      readonly __typename: "UnauthenticatedError";
      readonly message: string | null;
    } | {
      // This will never be '%other', but we need some
      // value in case none of the concrete values match.
      readonly __typename: "%other";
    } | null;
  } | null;
};
export type PipelineJobCloneButtonMutation = {
  response: PipelineJobCloneButtonMutation$data;
  variables: PipelineJobCloneButtonMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "id",
    "storageKey": null
  }
],
v4 = {
  "kind": "InlineFragment",
  "selections": (v3/*: any*/),
  "type": "PipelineJob",
  "abstractKey": null
},
v5 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    }
  ],
  "type": "UnauthenticatedError",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PipelineJobCloneButtonMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ClonePipelineJobPayload",
        "kind": "LinkedField",
        "name": "clonePipelineJob",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "pipelineJob",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PipelineJobCloneButtonMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ClonePipelineJobPayload",
        "kind": "LinkedField",
        "name": "clonePipelineJob",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "pipelineJob",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": (v3/*: any*/),
                "type": "Node",
                "abstractKey": "__isNode"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "87dba4ebf1230ead96ffdf30d9395603",
    "id": null,
    "metadata": {},
    "name": "PipelineJobCloneButtonMutation",
    "operationKind": "mutation",
    "text": "mutation PipelineJobCloneButtonMutation(\n  $input: ClonePipelineJobInput!\n) {\n  clonePipelineJob(input: $input) {\n    pipelineJob {\n      __typename\n      ... on PipelineJob {\n        id\n      }\n      ... on UnauthenticatedError {\n        message\n      }\n      ... on Node {\n        __isNode: __typename\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "a5de28a4b35e516cde31f654b076993a";

export default node;
