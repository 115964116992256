import {
  ArrowRightOutlined,
  EditOutlined,
  ExclamationCircleFilled,
  HomeOutlined,
  ReconciliationOutlined,
  WarningTwoTone,
} from "@ant-design/icons";
import { useToggle } from "ahooks";
import Alert from "antd/es/alert";
import Badge from "antd/es/badge";
import Breadcrumb, { BreadcrumbItemType } from "antd/es/breadcrumb/Breadcrumb";
import Button from "antd/es/button";
import Descriptions, { DescriptionsProps } from "antd/es/descriptions";
import Divider from "antd/es/divider";
import Grid from "antd/es/grid";
import Layout from "antd/es/layout";
import Space from "antd/es/space";
import Table from "antd/es/table";
import Tag from "antd/es/tag";
import Tooltip from "antd/es/tooltip";
import Typography from "antd/es/typography";
import message from "antd/lib/message";
import theme from "antd/lib/theme";
import graphql from "babel-plugin-relay/macro";
import dayjs from "dayjs";
import { useAtomValue } from "jotai";
import _ from "lodash";
import { ExternalLinkIcon, LucideUsersRound } from "lucide-react";
import { useEffect, useMemo, useState } from "react";
import { useLazyLoadQuery, useMutation } from "react-relay";
import { Link, useNavigate, useParams } from "react-router-dom";
import SyntaxHighlighter, { createElement } from "react-syntax-highlighter";
import { a11yDark } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { ReactFlowProvider } from "reactflow";
import { StringParam, useQueryParam, withDefault } from "use-query-params";

import AutoRefreshSwitch from "../components/AutoRefreshSwitch";
import ContainerLogModal from "../components/ContainerLogModal";
import Flex from "../components/Flex";
import { FolderExplorerButton } from "../components/FolderExplorer/FolderExplorer";
import NotFoundTemplate from "../components/NotFoundTemplate";
import PipelineJobArchiveButton from "../components/PipelineJobArchiveButton";
import PipelineJobCancelButton from "../components/PipelineJobCancelButton";
import PipelineJobCloneButton from "../components/PipelineJobCloneButton";
import PipelineJobDeleteButton from "../components/PipelineJobDeleteButton";
import PipelineJobProgress from "../components/PipelineJobProgress";
import PipelineJobResultIcon from "../components/PipelineJobResultIcon";
import PipelineJobStatusTag from "../components/PipelineJobStatusTag";
import PipelineJobUnarchiveButton from "../components/PipelineJobUnarchiveButton";
import PipelineJobYamlCodeViewerButton from "../components/PipelineJobYamlCodeViewerButton";
import { ClusterMode } from "../components/PipelineTaskForm";
import PipelineYamlEditor from "../components/PipelineYamlEditor";
import PrioritySettingModal from "../components/PrioritySettingModal";
import TaskInstanceExecutionTimeTag from "../components/TaskInstanceExecutionTimeTag";
import TaskInstanceResourceSlot from "../components/TaskInstanceResourceSlot";
import TaskInstanceResultIcon from "../components/TaskInstanceResultIcon";
import TaskInstanceStatusTag from "../components/TaskInstanceStatusTag";
import {
  DEFAULT_VFOLDER_ALIAS_PATH,
  DEFAULT_VFOLDER_ALIAS_SEPARATOR,
  getDefaultOutputFolderName,
} from "../components/VirtualFolderAliasInput";
import WhiteSpace from "../components/WhiteSpace";
import {
  from_global_id,
  getWebServerEndpoint,
  isFinalStatus,
} from "../helpers";
import { folderAtom, projectAtom } from "../hooks/atoms";
import { ResourceSlot, useImageMetadata } from "../hooks/backendai";
import {
  PipelineJobDetailQuery,
  PipelineJobDetailQuery$data,
} from "./__generated__/PipelineJobDetailQuery.graphql";
import { PipelineJobDetailSetPipelineJobPriorityMutation } from "./__generated__/PipelineJobDetailSetPipelineJobPriorityMutation.graphql";
import { PipelineJobDetailSetTaskInstancePriorityMutation } from "./__generated__/PipelineJobDetailSetTaskInstancePriorityMutation.graphql";

const { Text, Title } = Typography;

type TaskInstanceJobType = NonNullableNodeOnEdges<
  NonNullable<PipelineJobDetailQuery$data["pipelineJob"]>["taskinstanceSet"]
>;

type TaskInstanceEdge = {
  node: TaskInstanceJobType | null;
};

const PipelineJobDetail = () => {
  const { jobId } = useParams<"jobId">();
  const [tab, setTab] = useQueryParam(
    "tab",
    withDefault(StringParam, "active")
  );
  const [fetchKey, setFetchKey] = useState(0);
  const { token } = theme.useToken();
  const { md } = Grid.useBreakpoint();
  const [selectedSessionInfoForLogs, setSelectedSessionInfoForLog] = useState<{
    project: string;
    sessionId: string;
    taskInstanceGlobalId: string;
    taskName: string;
  }>();
  const [visiblePrioritySettingModal, { toggle: togglePrioritySettingModal }] =
    useToggle(false);
  interface TaskInstanceConfig {
    name: string;
    id: string;
    priority: number;
    // Add other properties if needed
  }

  const [currentTaskInstance, setCurrentTaskInstance] =
    useState<TaskInstanceConfig | null>(null);

  const { pipelineJob } = useLazyLoadQuery<PipelineJobDetailQuery>(
    graphql`
      query PipelineJobDetailQuery($jobId: ID!) {
        pipelineJob(id: $jobId) {
          id
          name
          owner {
            email
          }
          project
          yaml
          createdAt
          terminatedAt
          status
          result
          recordStatus
          pipeline {
            id
            name
            storage
            scheduledTask {
              crontab {
                ...CrontabViewFragment
              }
            }
            ...PipelineYamlEditorFragment
          }
          pipelineVersion {
            id
            yaml
            dataflow
          }
          ...PipelineYamlEditorJobFragment
          ...PipelineJobCancelButtonFragment
          ...PipelineJobProgressFragment
          ...PipelineJobStatusTag_pipelineJob
          ...PipelineJobResultIcon_pipelineJob
          ...PipelineJobCloneButton_pipelineJob
          ...PipelineJobArchiveButton_pipelineJob
          ...PipelineJobUnarchiveButton_pipelineJob
          ...PipelineJobDeleteButton_pipelineJob
          taskinstanceSet {
            edges {
              node {
                id
                computeSessionId
                priority
                status
                statusReason
                result
                createdAt
                config
                terminatedAt
                ...TaskInstanceStatusTag_taskInstance
                ...TaskInstanceResultIcon_taskInstance
              }
            }
          }
        }
      }
    `,
    {
      jobId: jobId as string,
    },
    {
      fetchKey: `${fetchKey}`,
      fetchPolicy: "store-and-network",
    }
  );

  if (pipelineJob === null) {
    return (
      <NotFoundTemplate
        title="Job id is not valid."
        subTitle="Please select a valid job id."
      />
    );
  }

  const [commitSetPipelineJobPriority, isInFlightSetPipelineJobPriority] =
    useMutation<PipelineJobDetailSetPipelineJobPriorityMutation>(graphql`
      mutation PipelineJobDetailSetPipelineJobPriorityMutation(
        $input: SetPipelineJobPriorityInput!
      ) {
        setPipelineJobPriority(input: $input) {
          pipelineJob {
            id
            taskinstanceSet {
              edges {
                node {
                  id
                  priority
                }
              }
            }
          }
        }
      }
    `);

  const [
    commitSetTaskInstancePriority,
    isInFlightCommitSetTaskInstancePriority,
  ] = useMutation<PipelineJobDetailSetTaskInstancePriorityMutation>(graphql`
    mutation PipelineJobDetailSetTaskInstancePriorityMutation(
      $input: SetTaskInstancePriorityInput!
    ) {
      setTaskInstancePriority(input: $input) {
        taskInstance {
          id
          priority
        }
      }
    }
  `);

  const project = useAtomValue(projectAtom);
  const navigate = useNavigate();
  useEffect(() => {
    if (
      pipelineJob.project !== null &&
      project !== undefined &&
      pipelineJob.project !== project.name
    ) {
      return navigate(`/pipeline-jobs?tab=${tab}`, { replace: true });
    }
  }, [project]);

  const rawPipelineJobId = from_global_id(pipelineJob.id || "")[1];

  const sortedTaskInstanceEdges: TaskInstanceEdge[] = useMemo(() => {
    const taskInstances: TaskInstanceEdge[] = [];
    const queue =
      pipelineJob.taskinstanceSet.edges.filter((t) => {
        const config = JSON.parse(t?.node?.config);
        return config.dependencies.length === 0;
      }) || [];
    while (queue.length > 0) {
      const taskInstance = queue.shift();
      if (_.isNil(taskInstance) || taskInstances.includes(taskInstance)) {
        continue;
      }
      const taskInstanceConfig = JSON.parse(taskInstance?.node?.config);
      const nextTaskInstances =
        pipelineJob.taskinstanceSet.edges.filter((t) => {
          const config = JSON.parse(t?.node?.config);
          return config.dependencies.includes(taskInstanceConfig.name);
        }) || [];
      queue.push(...nextTaskInstances);
      taskInstances.push(taskInstance);
    }
    return taskInstances;
  }, [pipelineJob.taskinstanceSet.edges]);

  const vFolderName: string | undefined =
    pipelineJob.pipeline?.storage &&
    JSON.parse(pipelineJob.pipeline.storage)?.name;
  const vFolder = vFolderName && useAtomValue(folderAtom({ vFolderName }));

  const items: BreadcrumbItemType[] = [
    {
      title: (
        <Link to={"/"}>
          <HomeOutlined />
        </Link>
      ),
    },
    {
      title: <Link to={`/pipeline-jobs?tab=${tab}`}>Pipeline Jobs</Link>,
    },
    {
      title: rawPipelineJobId,
    },
  ];

  const containerImageMetadata = useImageMetadata();

  const isInactivePipelineJob = ["TERMINATED", "CANCELLED"].includes(
    pipelineJob.status
  );
  const descriptionsItems: DescriptionsProps["items"] = [
    {
      label: "Name",
      children: (
        <Flex direction="row">
          {pipelineJob.name}
          <WhiteSpace direction="row" size="sm" />
          <PipelineJobResultIcon pipelineJobFragment={pipelineJob} />
          {pipelineJob.recordStatus === "ARCHIVED" ? (
            <>
              <WhiteSpace direction="row" size="sm" />
              <Tag
                style={{
                  borderRadius: 16,
                  fontSize: 12,
                  color: "#454545",
                  backgroundColor: "transparent",
                  borderColor: "#454545",
                }}
              >
                Archived
              </Tag>
            </>
          ) : null}
        </Flex>
      ),
      span: { xs: 1, sm: 1, md: 1, lg: 1, xl: 1, xxl: 3 },
    },
    {
      label: "Priority",
      children: (
        <>
          <Flex justify="end" gap={"xs"}>
            <Typography.Text>
              {Math.max(
                ...pipelineJob.taskinstanceSet.edges.map(
                  (e) => e?.node?.priority ?? 10
                )
              )}
            </Typography.Text>
            {pipelineJob.project !== null ? (
              <Tooltip title="Change Priority">
                <Button
                  size={"small"}
                  icon={<EditOutlined />}
                  onClick={() => {
                    setCurrentTaskInstance(null);
                    togglePrioritySettingModal();
                  }}
                  loading={isInFlightSetPipelineJobPriority}
                  disabled={
                    isInFlightSetPipelineJobPriority || isInactivePipelineJob
                  }
                />
              </Tooltip>
            ) : null}
          </Flex>
        </>
      ),
      span: { xs: 1, sm: 1, md: 1, lg: 1, xl: 1, xxl: 1 },
    },
    {
      label: "Status",
      children: (
        <Flex direction="row" align="center">
          <PipelineJobStatusTag pipelineJobFragment={pipelineJob} />
          <Divider type="vertical" />
          <PipelineJobProgress pipelineJobFgmt={pipelineJob} />
        </Flex>
      ),
      span: { xs: 1, sm: 1, md: 2, lg: 2, xl: 2, xxl: 2 },
    },
    pipelineJob.project !== null
      ? {
          label: "Project",
          children: (
            <Flex direction="row" align="center" justify="start">
              <LucideUsersRound
                style={{ marginRight: 8 }}
                color={token.colorTextSecondary}
              />
              {pipelineJob.project}
            </Flex>
          ),
          span: { xs: 1, sm: 1, md: 1, lg: 1, xl: 1, xxl: 1 },
        }
      : {
          label: "Owner",
          children: <>{pipelineJob.owner.email}</>,
          span: { xs: 1, sm: 1, md: 1, lg: 1, xl: 1, xxl: 1 },
        },
    {
      label: "Pipeline",
      children: (
        <Flex direction="row" align="center" justify="center">
          <Flex direction="column" align="center" justify="center">
            <Typography.Text
              style={{
                color: token.colorLink,
                width:
                  (pipelineJob.pipeline?.name.length ?? 0) > 24
                    ? 96
                    : undefined,
              }}
              ellipsis={{
                tooltip: pipelineJob.pipeline?.name,
              }}
              onClick={() => navigate(`/pipelines/${pipelineJob.pipeline?.id}`)}
            >
              <a role="button">{pipelineJob.pipeline?.name}</a>
            </Typography.Text>
            {pipelineJob.pipelineVersion?.id && (
              <Flex direction="row" align="center">
                {"("}
                <Link
                  to={`/pipelines/${pipelineJob.pipeline?.id}?pipelineVersionId=${pipelineJob.pipelineVersion.id}`}
                  style={{
                    color: token.colorTextDescription,
                    textDecoration: "underline",
                  }}
                >
                  {from_global_id(
                    pipelineJob.pipelineVersion.id || ""
                  )[1].slice(0, 8)}
                </Link>
                {")"}
              </Flex>
            )}
          </Flex>
          <WhiteSpace direction="row" size={"sm"} />
          <PipelineJobYamlCodeViewerButton pipelineJobId={pipelineJob.id} />
        </Flex>
      ),
      span: { xs: 1, sm: 1, md: 1, lg: 1, xl: 1, xxl: 1 },
    },
    {
      label: "Created",
      children: dayjs(pipelineJob.createdAt).format("lll"),
      span: { xs: 1, sm: 1, md: 1, lg: 1, xl: 1, xxl: 1 },
    },
    {
      label: "Terminated",
      children: pipelineJob.terminatedAt
        ? dayjs(pipelineJob.terminatedAt).format("lll")
        : "-",
      span: { xs: 1, sm: 1, md: 1, lg: 1, xl: 1, xxl: 1 },
    },
    {
      label: "Virtual Folder",
      children: vFolderName ? (
        <Flex direction="row" gap="xs">
          <FolderExplorerButton
            folderName={vFolderName}
            disabled={_.isNil(vFolder)}
          >
            {vFolderName}
          </FolderExplorerButton>
          {_.isNil(vFolder) ? (
            <Tooltip title="The dedicated virtual folder for the pipeline doesn't exist.">
              <WarningTwoTone twoToneColor={token.colorWarning} />
            </Tooltip>
          ) : null}
        </Flex>
      ) : null,
    },
  ];

  return (
    <Layout>
      <Space
        direction="vertical"
        style={{
          margin: 16,
          padding: 16,
          backgroundColor: "#fff",
        }}
      >
        <Flex direction="row" justify="between">
          <Breadcrumb items={items} />
          {isFinalStatus(pipelineJob.status) ? (
            <Flex direction="row" justify="between">
              <PipelineJobCloneButton pipelineJobFragment={pipelineJob} />
              {pipelineJob.recordStatus === "ACTIVE" ? (
                <PipelineJobArchiveButton
                  pipelineJobFragment={pipelineJob}
                  onCompleted={() => setFetchKey((v) => v + 1)}
                />
              ) : (
                <>
                  <PipelineJobDeleteButton
                    pipelineJobFragment={pipelineJob}
                    onCompleted={() =>
                      navigate(`/pipeline-jobs?tab=${tab}`, { replace: true })
                    }
                  />
                  <WhiteSpace direction="row" size="xs" />
                  <PipelineJobUnarchiveButton
                    pipelineJobFragment={pipelineJob}
                    onCompleted={() => setFetchKey((v) => v + 1)}
                  />
                </>
              )}
            </Flex>
          ) : (
            <Flex direction="row">
              <AutoRefreshSwitch
                onRefresh={() => {
                  setFetchKey((v) => v + 1);
                }}
                interval={5000}
              >
                Auto-refresh every 5 seconds
              </AutoRefreshSwitch>
              <WhiteSpace direction="row" />
              <PipelineJobCancelButton
                pipelineJobFragment={pipelineJob}
                danger
                type="default"
              >
                Cancel job
              </PipelineJobCancelButton>
            </Flex>
          )}
        </Flex>
        <Descriptions
          size="small"
          bordered
          column={{ xs: 1, sm: 1, md: 2, lg: 2, xl: 2, xxl: 4 }}
          items={descriptionsItems}
        />
        <div style={{ height: "400px" }}>
          <ReactFlowProvider>
            <PipelineYamlEditor pipelineJobFrgmt={pipelineJob} />
          </ReactFlowProvider>
        </div>
        <Table
          scroll={{ x: "max-content" }}
          pagination={{ position: ["bottomCenter"] }}
          columns={[
            // {
            //   title: "id",
            //   dataIndex: "id",
            //   render: (id) => atob(id).split(":")[1],
            //   ellipsis: true,
            // },
            {
              title: "",
              dataIndex: "result",
              render: (text, record) => (
                <TaskInstanceResultIcon taskInstanceFragment={record} />
              ),
              fixed: "left",
            },
            {
              title: "Task Name",
              render: (text, record) => {
                const config = JSON.parse(record.config);
                return (
                  <Flex
                    direction="row"
                    align="center"
                    justify="start"
                    gap={"xxs"}
                  >
                    {config.name}
                    <Divider type={"vertical"} />
                    <Flex
                      direction="row"
                      align="end"
                      justify="center"
                      gap={"xxs"}
                    >
                      <Tooltip
                        title={
                          record.computeSessionId
                            ? "Open logs"
                            : "Compute session is not ready."
                        }
                      >
                        <Button
                          type="link"
                          icon={<ReconciliationOutlined />}
                          size={"small"}
                          onClick={() => {
                            const config = JSON.parse(record.config);
                            setSelectedSessionInfoForLog({
                              project:
                                config.environment.project ??
                                pipelineJob.project,
                              sessionId: record.computeSessionId,
                              taskInstanceGlobalId: record.id,
                              taskName: JSON.parse(record.config).name,
                            });
                          }}
                          disabled={record.computeSessionId === null}
                        />
                      </Tooltip>
                      <Tooltip
                        title={
                          record.computeSessionId
                            ? "Open session detail page"
                            : "Compute session is not ready."
                        }
                      >
                        <Button
                          type={"link"}
                          icon={<ExternalLinkIcon />}
                          size={"small"}
                          href={`${getWebServerEndpoint()}/job?sessionDetail=${record.computeSessionId}`}
                          target="_blank"
                          disabled={record.computeSessionId === null}
                        />
                      </Tooltip>
                    </Flex>
                  </Flex>
                );
              },
              fixed: "left",
            },
            {
              title: "Priority",
              dataIndex: "priority",
              render: (value, record) => {
                return (
                  <Flex direction="row" align="center" justify="end">
                    <Typography.Text>{value}</Typography.Text>
                    {pipelineJob.project !== null ? (
                      <Tooltip title={"Change Priority"}>
                        <Button
                          type="link"
                          icon={<EditOutlined />}
                          onClick={() => {
                            const config = JSON.parse(record.config);
                            setCurrentTaskInstance({
                              name: config.name,
                              id: record.id,
                              priority: record.priority,
                            });
                            togglePrioritySettingModal();
                          }}
                          disabled={
                            record.status !== "PENDING" || isInactivePipelineJob
                          }
                        />
                      </Tooltip>
                    ) : null}
                  </Flex>
                );
              },
            },
            {
              title: "Image",
              render: (text, record) => {
                const config = JSON.parse(record.config);
                const { image } = config.environment;
                const [containerImageName, version] = image.split(":", 2);
                const metadata =
                  containerImageMetadata.imageInfo[
                    containerImageName.split("/").pop()
                  ];
                return (
                  <Flex direction="column" align="start" justify="center">
                    <Flex direction="row">
                      <img
                        src={`/static/icons/${metadata?.icon}`}
                        style={{ width: 18, height: 18 }}
                      />
                      <WhiteSpace direction="row" size="xs" />
                      <Tag color={token.colorPrimary}>{version}</Tag>
                      <Text copyable={{ text: image }} />
                    </Flex>
                    <WhiteSpace direction="column" size="xs" />
                    <Flex direction="row">
                      {metadata?.label.map((label, i) => (
                        <div key={i}>
                          {i > 0 ? (
                            <WhiteSpace direction="row" size="xs" />
                          ) : null}
                          <Tag color={label.color}>{label.tag}</Tag>
                        </div>
                      ))}
                    </Flex>
                  </Flex>
                );
              },
            },
            {
              title: "Cluster Mode",
              render: (text, record) => {
                const config = JSON.parse(record.config);
                const clusterMode: ClusterMode =
                  config.cluster_mode || "single-node";
                const clusterSize = config.cluster_size || 1;
                return (
                  <Flex direction={"row"} align="center" justify="start">
                    <Tag
                      color={clusterMode === "multi-node" ? "darkblue" : "blue"}
                    >
                      {clusterMode}
                    </Tag>
                    {clusterSize > 1 && (
                      <Badge
                        count={` X ${clusterSize}`}
                        color={token.colorInfoBorderHover}
                      />
                    )}
                  </Flex>
                );
              },
            },
            {
              title: "Resources",
              render: (text, record) => {
                const config = JSON.parse(record.config);
                const accelerator = Object.keys(config.resources).find(
                  (resource) => !["cpu", "mem"].includes(resource)
                ) as ResourceSlot;
                return (
                  <>
                    <TaskInstanceResourceSlot
                      resourceSlot="cpu"
                      value={parseInt(config.resources.cpu)}
                    />
                    <TaskInstanceResourceSlot
                      resourceSlot="mem"
                      value={parseFloat(config.resources.mem.replace("g", ""))}
                    />
                    {accelerator && (
                      <TaskInstanceResourceSlot
                        resourceSlot={accelerator}
                        value={parseFloat(config.resources[accelerator])}
                      />
                    )}
                  </>
                );
              },
            },
            {
              title: "Status",
              dataIndex: "status",
              render: (status, record) => {
                const statusReason = JSON.parse(record.statusReason);
                return (
                  <Flex direction="row" align="center">
                    <TaskInstanceStatusTag taskInstanceFragment={record} />
                    {!_.isNil(statusReason.msg) ? (
                      <>
                        <WhiteSpace direction="row" />
                        <Tooltip title={statusReason.msg}>
                          <ExclamationCircleFilled
                            style={{ color: token.colorErrorText }}
                          />
                        </Tooltip>
                      </>
                    ) : null}
                  </Flex>
                );
              },
            },
            {
              title: "Terminated",
              dataIndex: "terminatedAt",
              render: (text, record) => {
                return (
                  <Flex direction="column" align="start">
                    {text ? dayjs(text).format("lll") : "-"}
                    <TaskInstanceExecutionTimeTag
                      computeSessionId={record.computeSessionId}
                      fetchKey={fetchKey}
                    />
                  </Flex>
                );
              },
            },
            {
              title: "Virtual Folders",
              dataIndex: "config",
              render: (config) => {
                const task: {
                  name: string;
                  mounts?: string[];
                } = JSON.parse(config);
                if (task.mounts) {
                  return (
                    <ul>
                      {_.map(task.mounts, (m: string) => {
                        let [realPath, alias] = m.split(
                          DEFAULT_VFOLDER_ALIAS_SEPARATOR
                        );
                        if (realPath === vFolderName) {
                          realPath = getDefaultOutputFolderName(
                            vFolderName,
                            task.name
                          );
                          alias = DEFAULT_VFOLDER_ALIAS_PATH;
                        }
                        const [folderName, ...pathItems] = realPath.split("/");
                        return (
                          <li key={m}>
                            <FolderExplorerButton
                              folderName={folderName}
                              disabled={_.isNil(vFolder)}
                              initialPath={[".", ...pathItems].join("/")}
                            >
                              <>
                                {realPath}
                                {alias ? (
                                  <>
                                    <ArrowRightOutlined
                                      style={{
                                        paddingLeft: 4,
                                        paddingRight: 4,
                                      }}
                                    />
                                    {alias}
                                  </>
                                ) : undefined}
                              </>
                            </FolderExplorerButton>
                          </li>
                        );
                      })}
                    </ul>
                  );
                } else {
                  return null;
                }
              },
            },
          ]}
          expandable={{
            rowExpandable: (record) => true,
            expandedRowRender: (record) => {
              const config = JSON.parse(record.config);
              return (
                <>
                  <Title level={5} style={{ margin: 0, fontWeight: 500 }}>
                    Command
                  </Title>
                  <WhiteSpace direction="column" size="md" />
                  <SyntaxHighlighter
                    language="bash"
                    style={a11yDark}
                    showLineNumbers={true}
                    wrapLongLines={true}
                    renderer={({ rows, stylesheet, useInlineStyles }) => {
                      return rows.map((row, index) => {
                        const children = row.children;
                        const lineNumberElement = children?.shift();

                        /**
                         * We will take current structure of the rows and rebuild it
                         * according to the suggestion here https://github.com/react-syntax-highlighter/react-syntax-highlighter/issues/376#issuecomment-1246115899
                         */
                        if (lineNumberElement) {
                          row.children = [
                            lineNumberElement,
                            {
                              children,
                              properties: {
                                className: [],
                              },
                              tagName: "span",
                              type: "element",
                            },
                          ];
                        }

                        return createElement({
                          node: row,
                          stylesheet,
                          useInlineStyles,
                          key: index,
                        });
                      });
                    }}
                  >
                    {config.command}
                  </SyntaxHighlighter>
                </>
              );
            },
          }}
          dataSource={
            _.map(
              sortedTaskInstanceEdges,
              (e) => e?.node
            ) as TaskInstanceJobType[]
          }
          rowKey={(record) => record.id}
        />
      </Space>
      {selectedSessionInfoForLogs && (
        <ContainerLogModal
          open={!!selectedSessionInfoForLogs}
          taskInstanceGlobalId={
            selectedSessionInfoForLogs?.taskInstanceGlobalId
          }
          sessionId={selectedSessionInfoForLogs?.sessionId}
          project={selectedSessionInfoForLogs?.project}
          title={
            <Flex style={{ maxWidth: "100%" }} gap={"sm"}>
              <Typography.Title level={4} style={{ margin: 0, flexShrink: 0 }}>
                Logs
              </Typography.Title>
              <Divider type={"vertical"} />
              <Typography.Text
                style={{ fontWeight: "normal", fontFamily: "monospace" }}
                ellipsis
              >
                {selectedSessionInfoForLogs?.taskName}
              </Typography.Text>
              <Divider type={"vertical"} />
              <Typography.Text
                style={{ fontWeight: "normal", fontFamily: "monospace" }}
                copyable={{
                  text: selectedSessionInfoForLogs?.sessionId,
                  tooltips: `Copy session ID: ${selectedSessionInfoForLogs?.sessionId.split("-")[0]}`,
                }}
              >
                {"Session: "}
                {md
                  ? selectedSessionInfoForLogs?.sessionId
                  : selectedSessionInfoForLogs?.sessionId.split("-")[0]}
              </Typography.Text>
            </Flex>
          }
          onRequestClose={() => {
            setSelectedSessionInfoForLog(undefined);
          }}
          destroyOnClose
        />
      )}
      <PrioritySettingModal
        open={visiblePrioritySettingModal}
        onSetting={async (priority: number) => {
          if (currentTaskInstance !== null) {
            commitSetTaskInstancePriority({
              variables: {
                input: {
                  id: currentTaskInstance.id,
                  priority: priority,
                },
              },
              onCompleted(response, errors) {
                message.success(`Priority has been updated to ${priority}.`);
                setFetchKey((v) => v + 1);
              },
              onError(error) {
                //
              },
            });
          } else {
            commitSetPipelineJobPriority({
              variables: {
                input: {
                  id: pipelineJob.id,
                  priority: priority,
                },
              },
              onCompleted(response, errors) {
                message.success("Priority has been updated.");
                setFetchKey((v) => v + 1);
              },
              onError(error) {
                //
              },
            });
          }
        }}
        onRequestClose={togglePrioritySettingModal}
        title={
          <Typography.Text strong>
            {currentTaskInstance
              ? `Change priority of ${currentTaskInstance.name}`
              : "Change priorities"}
          </Typography.Text>
        }
        warning={
          !currentTaskInstance && (
            <Alert
              style={{ width: "100%" }}
              showIcon
              message="This will change all priorities of task instance in the pipeline job."
              type="warning"
            />
          )
        }
        defaultValue={
          currentTaskInstance
            ? currentTaskInstance.priority
            : Math.max(
                ...pipelineJob.taskinstanceSet.edges.map(
                  (e) => e?.node?.priority ?? 10
                )
              )
        }
      />
    </Layout>
  );
};

export default PipelineJobDetail;
