import { ExclamationCircleFilled } from "@ant-design/icons";
import { createStyles } from "antd-style";
import Button from "antd/es/button";
import Divider from "antd/es/divider";
import Tooltip from "antd/es/tooltip";
import Typography from "antd/es/typography";
import theme from "antd/lib/theme";
import _ from "lodash";
import {
  LucideChevronRight,
  LucideCopyPlus,
  LucidePlugZap,
  LucideTrash2,
  LucideUnplug,
} from "lucide-react";
import React, { useState } from "react";
import { Handle, NodeProps, Position } from "reactflow";

import { ResourceSlot, useImageMetadata } from "../../hooks/backendai";
import Flex from "../Flex";
import { TaskNodeData } from "../PipelineYamlEditor";
import { getResourceSlotMetadata } from "../TaskInstanceResourceSlot";
import "./TaskNode.css";
import TaskNodeDeletePopconfirm from "./TaskNodeDeletePopconfirm";

const useStyles = createStyles(({ token, css }) => {
  return {
    reactFlowNode: css`
      .react-flow__node {
        padding-top: 0px;
        padding-bottom: 0px;
      }
      .react-flow__handle {
        background: #ddd;
        border: 1px solid #333;
      }
      .react-flow__handle-left {
        left: -8px;
        border-radius: 0px;
      }
      .react-flow__handle-right {
        right: -8px;
        border-radius: 8px;
      }
    `,
  };
});

const sourceHandleStyle: React.CSSProperties = {
  height: "14px",
  width: "14px",
  borderRadius: 8,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
};

const targetHandleStyle: React.CSSProperties = {
  height: "24px",
  width: "8px",
};

const TaskNode: React.FC<NodeProps<TaskNodeData>> = ({ data }) => {
  const { token } = theme.useToken();
  const { styles } = useStyles();
  const [isControlMenuVisible, setIsControlMenuVisible] =
    useState<boolean>(false);
  const [isDuplicateOnHover, setIsDuplicateOnHover] = useState<boolean>(false);
  const [isPopconfirmOpen, setIsPopconfirmOpen] = useState<boolean>(false);

  const containerImageMetadata = useImageMetadata();
  const imageName = data.task?.environment?.image;
  const untaggedImageName = imageName
    ? _.last(imageName.split(":")[0].split("/"))
    : undefined;

  const imageMetadata = untaggedImageName
    ? containerImageMetadata.imageInfo[untaggedImageName]
    : undefined;

  const resourceKeys = Object.keys(data.task?.resources ?? {});
  const accelerator = _.difference(resourceKeys, ["cpu", "mem"])?.[0] as
    | ResourceSlot
    | undefined;

  const resourceSlotMetadata = accelerator
    ? getResourceSlotMetadata(accelerator)
    : null;

  const isInactive = ["TERMINATED", "CANCELLED"].includes(
    data.task?.status ?? ""
  );
  const grayscale = isInactive ? 80 : 0;

  return (
    <div
      className={styles.reactFlowNode}
      onMouseEnter={(e) => setIsControlMenuVisible(true)}
      onMouseLeave={(e) => setIsControlMenuVisible(false)}
    >
      {data.isEditable && (isControlMenuVisible || isPopconfirmOpen) ? (
        <div
          style={{ position: "absolute", marginTop: -48, paddingBottom: 24 }}
        >
          <Flex
            direction="row"
            align="center"
            justify="center"
            style={{
              width: 128,
              background: token.colorBgTextHover,
              borderRadius: 8,
            }}
          >
            <Tooltip title={"Duplicate"}>
              <Button
                className="nodrag"
                type="text"
                icon={<LucideCopyPlus />}
                style={{
                  color: isDuplicateOnHover
                    ? token.colorPrimaryTextHover
                    : "black",
                  backgroundColor: "transparent",
                }}
                onClick={data.onDuplicate}
                onMouseEnter={() => setIsDuplicateOnHover(true)}
                onMouseLeave={() => setIsDuplicateOnHover(false)}
              />
            </Tooltip>
            <TaskNodeDeletePopconfirm
              title={`Are you sure you want to delete this task node (${data.task?.name})?`}
              onConfirm={data.onDelete}
              onOpenChange={setIsPopconfirmOpen}
            >
              <Tooltip title={"Delete"}>
                <Button
                  className="nodrag"
                  type="text"
                  icon={<LucideTrash2 />}
                  style={{ backgroundColor: "transparent" }}
                  danger
                />
              </Tooltip>
            </TaskNodeDeletePopconfirm>
            <Tooltip title={data.task?.skip ? "Join" : "Skip"}>
              <Button
                className="nodrag"
                type="text"
                icon={data.task?.skip ? <LucidePlugZap /> : <LucideUnplug />}
                style={{ color: "black", backgroundColor: "transparent" }}
                onClick={data.onToggleSkip}
                loading={data.isInFlightCommitSkip}
              />
            </Tooltip>
          </Flex>
        </div>
      ) : null}
      <Handle
        type="target"
        position={Position.Left}
        style={targetHandleStyle}
      />
      <Flex
        direction="row"
        align="center"
        justify="center"
        gap={"xs"}
        style={{ width: 128, height: 64 }}
      >
        {imageMetadata ? (
          <img
            src={`/static/icons/${imageMetadata.icon}`}
            style={{
              width: 24,
              height: 24,
              filter: `grayscale(${grayscale}%)`,
            }}
          />
        ) : (
          <img
            src={`/static/manifest/backend.ai-fasttrack-brand-simple.svg`}
            style={{
              width: 24,
              height: 24,
              filter: `grayscale(${grayscale}%)`,
            }}
          />
        )}
        {resourceSlotMetadata ? (
          <>
            <Divider type="vertical" />
            <img
              src={`/static/icons/${resourceSlotMetadata.icon}`}
              style={{
                width: 24,
                height: 24,
                filter: `grayscale(${grayscale}%)`,
              }}
            />
          </>
        ) : null}
      </Flex>
      <Handle type="source" position={Position.Right} style={sourceHandleStyle}>
        <LucideChevronRight
          style={{ fontSize: 16, color: "#666", pointerEvents: "none" }}
        />
      </Handle>
      <Flex direction="row" align="center" justify="center">
        <Typography.Title
          level={5}
          style={{
            position: "absolute",
            marginTop: 32,
            paddingTop: 16,
            fontWeight: 400,
          }}
          ellipsis
        >
          {data.isMissingRequired ? (
            <ExclamationCircleFilled
              style={{ color: token.colorWarning, marginRight: 8 }}
            />
          ) : null}
          {data.label}
        </Typography.Title>
      </Flex>
    </div>
  );
};

export default TaskNode;
