/**
 * @generated SignedSource<<b6f2d54a8361036ce353f76f2ae8ca66>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PipelineJobCloneButton_pipelineJob$data = {
  readonly id: string;
  readonly name: string;
  readonly oneshotEnvs: any;
  readonly yaml: string;
  readonly " $fragmentType": "PipelineJobCloneButton_pipelineJob";
};
export type PipelineJobCloneButton_pipelineJob$key = {
  readonly " $data"?: PipelineJobCloneButton_pipelineJob$data;
  readonly " $fragmentSpreads": FragmentRefs<"PipelineJobCloneButton_pipelineJob">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PipelineJobCloneButton_pipelineJob",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "yaml",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "oneshotEnvs",
      "storageKey": null
    }
  ],
  "type": "PipelineJob",
  "abstractKey": null
};

(node as any).hash = "857538cc003dd8ec798dc680d2f03291";

export default node;
