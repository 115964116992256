import {
  ClockCircleOutlined,
  ExclamationCircleOutlined,
  MinusCircleOutlined,
  StopOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import Tooltip from "antd/es/tooltip";
import theme from "antd/lib/theme";
import graphql from "babel-plugin-relay/macro";
import { CircleAlertIcon } from "lucide-react";
import React from "react";
import { useFragment } from "react-relay";

import Flex from "./Flex";
import WhiteSpace from "./WhiteSpace";
import { PipelineJobStatusTag_pipelineJob$key } from "./__generated__/PipelineJobStatusTag_pipelineJob.graphql";

const PipelineJobStatusTag: React.FC<{
  pipelineJobFragment: PipelineJobStatusTag_pipelineJob$key;
}> = ({ pipelineJobFragment }) => {
  const { token } = theme.useToken();
  const pipelineJob = useFragment(
    graphql`
      fragment PipelineJobStatusTag_pipelineJob on PipelineJob {
        status
        terminatedBy {
          isSuperuser
        }
      }
    `,
    pipelineJobFragment
  );
  return (
    <Flex direction="row" align="center">
      {pipelineJob.status === "RUNNING" ? (
        <div style={{ color: token.colorSuccess }}>
          <SyncOutlined spin /> {pipelineJob.status}
        </div>
      ) : pipelineJob.status === "TERMINATED" ? (
        <div style={{ color: "gray" }}>
          <MinusCircleOutlined /> {pipelineJob.status}
        </div>
      ) : pipelineJob.status === "CANCELLED" ? (
        <div style={{ color: "gray" }}>
          <StopOutlined /> {pipelineJob.status}
        </div>
      ) : pipelineJob.status === "ERROR" ? (
        <div style={{ color: token.colorError }}>
          <ExclamationCircleOutlined /> {pipelineJob.status}
        </div>
      ) : (
        <div style={{ color: "gray" }}>
          <ClockCircleOutlined /> {pipelineJob.status}
        </div>
      )}
      {pipelineJob.terminatedBy?.isSuperuser ? (
        <>
          <WhiteSpace direction="row" />
          <Tooltip title={"Requested-by: admin"}>
            <CircleAlertIcon style={{ color: token.colorError }} />
          </Tooltip>
        </>
      ) : null}
    </Flex>
  );
};

export default PipelineJobStatusTag;
