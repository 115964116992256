import { ExclamationCircleFilled } from "@ant-design/icons";
import Popconfirm, { PopconfirmProps } from "antd/es/popconfirm";

interface Props extends Omit<PopconfirmProps, "title"> {
  title?: string;
}

const TaskNodeDeletePopconfirm: React.FC<Props> = ({
  onConfirm,
  children,
  ...props
}) => {
  return (
    <Popconfirm
      title={props.title ?? "Are you sure to delete this task node?"}
      icon={<ExclamationCircleFilled style={{ color: "red" }} />}
      onConfirm={onConfirm}
      okText={props.okText ?? "Delete"}
      cancelText={props.cancelText ?? "Cancel"}
      okButtonProps={{ danger: true }}
      onOpenChange={props.onOpenChange}
    >
      {children}
    </Popconfirm>
  );
};

export default TaskNodeDeletePopconfirm;
