import Button from "antd/es/button";
import Tooltip from "antd/es/tooltip";
import { FileCodeIcon } from "lucide-react";
import React, { Suspense, useState } from "react";

import PipelineJobYamlCodeViewerModal from "./PipelineJobYamlCodeViewerModal";

interface Props {
  pipelineJobId: string;
}

const PipelineJobYamlCodeViewerButton: React.FC<Props> = ({
  pipelineJobId,
  ...props
}) => {
  const [visibleModal, setVisibleModal] = useState(false);
  return (
    <Tooltip title={"View"}>
      <Button
        icon={<FileCodeIcon />}
        size={"small"}
        onClick={() => setVisibleModal(true)}
      />
      <Suspense
        fallback={
          <PipelineJobYamlCodeViewerModal
            pipelineJobId={""}
            open={visibleModal}
          />
        }
      >
        <PipelineJobYamlCodeViewerModal
          pipelineJobId={pipelineJobId}
          open={visibleModal}
          onCancel={() => setVisibleModal(false)}
        />
      </Suspense>
    </Tooltip>
  );
};

export default PipelineJobYamlCodeViewerButton;
