import { LoadingOutlined } from "@ant-design/icons";
import Spin from "antd/es/spin";
import React from "react";

import Flex, { FlexProps } from "./Flex";

const FlexActivityIndicator: React.FC<FlexProps> = ({ style, children }) => {
  return (
    <Flex
      direction="row"
      justify="center"
      align="center"
      style={{ width: "100%", height: "100%", ...style }}
    >
      <Spin indicator={<LoadingOutlined spin />} />
      {children}
    </Flex>
  );
};

export default FlexActivityIndicator;
