import { DependencyList, useEffect, useMemo, useRef, useState } from "react";

export const useMemoWithPrevious = <T,>(
  factory: () => T,
  deps: DependencyList,
  { initialValue }: { initialValue?: T } = {}
) => {
  const prevRef = useRef(initialValue);
  const [prevResetKey, setPrevResetKey] = useState({});

  const current = useMemo(factory, deps);
  const memoizedPrev = useMemo(() => {
    return prevRef.current;
    // Only update when the reset key changes and deps change
  }, [...deps, prevResetKey]);

  useEffect(() => {
    prevRef.current = current;
    // Only update when deps change
  }, [deps]);

  return [
    {
      previous: memoizedPrev,
      current: current,
    },
    {
      resetPrevious: () => {
        prevRef.current = initialValue;
        setPrevResetKey({});
      },
    },
  ] as const;
};
