import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  MinusCircleOutlined,
} from "@ant-design/icons";
import Tooltip from "antd/es/tooltip";
import theme from "antd/lib/theme";
import graphql from "babel-plugin-relay/macro";
import React from "react";
import { useFragment } from "react-relay";

import Flex from "./Flex";
import { TaskInstanceResultIcon_taskInstance$key } from "./__generated__/TaskInstanceResultIcon_taskInstance.graphql";

const TaskInstanceResultIcon: React.FC<{
  taskInstanceFragment: TaskInstanceResultIcon_taskInstance$key;
  children?: React.ReactNode;
}> = ({ taskInstanceFragment, children }) => {
  const { token } = theme.useToken();
  const taskInstance = useFragment(
    graphql`
      fragment TaskInstanceResultIcon_taskInstance on TaskInstance {
        result
      }
    `,
    taskInstanceFragment
  );

  return (
    <Flex
      direction="row"
      align="center"
      style={{ flex: 1, alignItems: "stretch" }}
    >
      <Tooltip title={taskInstance.result}>
        {taskInstance.result === "SUCCESS" ? (
          <CheckCircleOutlined style={{ color: token.colorSuccess }} />
        ) : taskInstance.result === "FAILURE" ? (
          <CloseCircleOutlined style={{ color: token.colorError }} />
        ) : (
          <MinusCircleOutlined style={{ color: "gray" }} />
        )}
      </Tooltip>
    </Flex>
  );
};

export default TaskInstanceResultIcon;
