import {
  DownOutlined,
  InfoCircleOutlined,
  MailOutlined,
  SafetyCertificateOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { useToggle } from "ahooks";
import Avatar from "antd/es/avatar";
import Dropdown from "antd/es/dropdown";
import Modal from "antd/es/modal";
import Typography from "antd/es/typography";
import { ItemType as MenuItemType } from "antd/lib/menu/interface";
import { LogOutIcon } from "lucide-react";
import React from "react";

import { useAuth } from "../hooks/auth";
import Flex from "./Flex";
import WhiteSpace from "./WhiteSpace";

const AboutFastTrack = React.lazy(() => import("./AboutFastTrack"));

const { Text } = Typography;

const UserDropdown = () => {
  const { user, logout } = useAuth();
  const [isOpenAbout, { toggle: toggleIsOpenAbout }] = useToggle();
  const items: MenuItemType[] = [
    {
      disabled: true,
      icon: <MailOutlined />,
      label: user?.email,
      key: "email",
    },
    {
      type: "divider",
      key: "divider",
    },
    {
      disabled: true,
      icon: <SafetyCertificateOutlined />,
      label: user?.role,
      key: "role",
    },
    {
      type: "divider",
      key: "divider2",
    },
    {
      key: "info",
      label: "About FastTrack",
      icon: <InfoCircleOutlined />,
      onClick: () => toggleIsOpenAbout(),
    },
    {
      label: "Logout",
      icon: <LogOutIcon />,
      key: "logout",
      onClick: () => logout(),
    },
  ];
  return (
    <Flex
      direction="column"
      justify="center"
      style={{ padding: 24, color: "#2A2C30" }}
      align="stretch"
    >
      <Flex direction="row" justify={"start"}>
        <Dropdown menu={{ items }} trigger={["click"]}>
          <Flex
            direction="row"
            justify={"between"}
            style={{ flex: 1, cursor: "pointer" }}
          >
            <Flex direction="row">
              <Avatar
                size="small"
                icon={<UserOutlined />}
                style={{ backgroundColor: "#BFBFBF" }}
              />
              <WhiteSpace direction="row" />
              <Text>{user?.full_name}</Text>
            </Flex>
            <DownOutlined style={{ marginLeft: 8 }} />
          </Flex>
        </Dropdown>
      </Flex>
      <Modal
        open={isOpenAbout}
        onOk={toggleIsOpenAbout}
        onCancel={toggleIsOpenAbout}
        footer={null}
        styles={{
          body: {
            padding: 0,
          },
        }}
        width={400}
      >
        <AboutFastTrack />
      </Modal>
    </Flex>
  );
};

export default UserDropdown;
