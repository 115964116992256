import {
  InfoCircleOutlined,
  LeftOutlined,
  PlusOutlined,
  RollbackOutlined,
  ShareAltOutlined,
} from "@ant-design/icons";
import Alert from "antd/es/alert";
import Button from "antd/es/button";
import Empty from "antd/es/empty";
import Form from "antd/es/form";
import { useForm } from "antd/es/form/Form";
import Input from "antd/es/input";
import Layout from "antd/es/layout";
import Popconfirm from "antd/es/popconfirm";
import Sider from "antd/lib/layout/Sider";
import { Content } from "antd/lib/layout/layout";
import theme from "antd/lib/theme";
import graphql from "babel-plugin-relay/macro";
import { useAtomValue } from "jotai";
import { Suspense, useEffect, useMemo, useRef, useState } from "react";
import { ConnectionHandler, useLazyLoadQuery, useMutation } from "react-relay";
import { useNavigate, useParams } from "react-router-dom";
import { StringParam, useQueryParam, useQueryParams } from "use-query-params";

import Flex from "../components/Flex";
import FlexActivityIndicator from "../components/FlexActivityIndicator";
import PipelineDetail from "../components/PipelineDetail";
import PipelineEditorModal from "../components/PipelineEditorModal";
import PipelineInfiniteList, {
  PipelineInfiniteListFallback,
} from "../components/PipelineInfiniteList";
import PipelineVersionSelector from "../components/PipelineVersionSelector";
import { projectAtom } from "../hooks/atoms";
import {
  PipelineFilterInputType,
  PipelineListQuery,
  PipelineListQuery$variables,
} from "./__generated__/PipelineListQuery.graphql";
import { PipelineListVersionRestoreMutation } from "./__generated__/PipelineListVersionRestoreMutation.graphql";

export const toInitialString = (name: string) => {
  const arr = name.split(" ");
  return arr
    .map((item) => item[0])
    .join("")
    .toUpperCase()
    .slice(0, 2);
};

const PipelineList = () => {
  const [visibleCreator, setVisibleCreator] = useState(false);

  const scrollDivRef = useRef<HTMLDivElement>(null);

  const [search, setSearch] = useState<string>();
  const [searchForm] = useForm<{
    search: string;
  }>();

  // Leaving this for backward compatibility
  const [queryParams] = useQueryParams({ pipelineId: StringParam });
  useEffect(() => {
    if (queryParams.pipelineId) {
      navigate(`/pipelines/${queryParams.pipelineId}`, { replace: true });
    }
  }, [queryParams.pipelineId]);

  const navigate = useNavigate();
  const { pipelineId } = useParams<"pipelineId">();
  const [selectedPipelineVersionId, setSelectedPipelineVersionId] =
    useQueryParam("pipelineVersionId", StringParam);

  const project = useAtomValue(projectAtom);

  const defaultQueryFilter: PipelineFilterInputType = {
    ...(project !== undefined
      ? {
          project: { exact: project.name },
        }
      : {}),
  };

  const queryVariables = useMemo<PipelineListQuery$variables>(
    () => ({
      first: 20,
      order: ["LAST_MODIFIED_DESC"],
      filter: search
        ? {
            ...defaultQueryFilter,
            name: {
              icontains: search,
            },
          }
        : defaultQueryFilter,
    }),
    [search, project]
  );

  const { token } = theme.useToken();

  // TODO: Inject
  const pipelineQueryRef = useLazyLoadQuery<PipelineListQuery>(
    graphql`
      query PipelineListQuery(
        $first: Int!
        $after: String
        $order: [PipelineOrder]
        $filter: PipelineFilterInputType
      ) {
        ...PipelineInfiniteListPaginationFragment
      }
    `,
    queryVariables
  );

  const [commitRestore, isInFlightCommitRestore] =
    useMutation<PipelineListVersionRestoreMutation>(graphql`
      mutation PipelineListVersionRestoreMutation(
        $input: RestorePipelineVersionInput!
      ) {
        restorePipelineVersion(input: $input) {
          pipeline {
            __typename
            ... on Pipeline {
              id
              name
              description
              yaml
              dataflow
              lastModified
              storage
            }
            ... on UnauthenticatedError {
              message
            }
          }
        }
      }
    `);

  return (
    <Layout
      style={{
        height: "100%",
        padding: 16,
        backgroundColor: token.colorBgLayout,
      }}
    >
      {/* TODO: Independent Component */}
      <Layout
        hasSider
        style={{
          border: `1px solid ${token.colorBorder}`,
        }}
      >
        {selectedPipelineVersionId ? (
          <Sider
            theme="light"
            width={240}
            style={{ borderRight: `1px solid ${token.colorBorder}` }}
          >
            <Flex direction="column" style={{ width: "100%" }} align="stretch">
              <Flex
                direction="row"
                style={{
                  padding: 16,
                  backgroundColor: token.colorBgContainer,
                  borderBottom: `1px solid ${token.colorBorder}`,
                }}
                justify="between"
              >
                <Button
                  // size="small"
                  type="text"
                  icon={<LeftOutlined />}
                  onClick={() => {
                    setSelectedPipelineVersionId(undefined);
                  }}
                  style={{ paddingLeft: 0, paddingRight: 0 }}
                >
                  Back
                </Button>
                <Popconfirm
                  title="Restore Pipeline Version"
                  description="This action will overwrite this version's content to the pipeline. It cannot be undone."
                  okText="Restore"
                  okButtonProps={{
                    type: "default",
                    danger: true,
                  }}
                  onConfirm={() => {
                    return new Promise((resolve, reject) => {
                      commitRestore({
                        variables: {
                          input: {
                            id: selectedPipelineVersionId || "",
                          },
                        },
                        onCompleted(response) {
                          resolve(true);
                          setSelectedPipelineVersionId(undefined);
                        },
                        onError(error) {
                          reject();
                        },
                      });
                    });
                  }}
                >
                  <Button
                    type="primary"
                    key="rollbackButton"
                    icon={<RollbackOutlined />}
                    disabled={
                      selectedPipelineVersionId === "NONE" ||
                      isInFlightCommitRestore
                    }
                  >
                    Restore
                  </Button>
                </Popconfirm>
              </Flex>
              <PipelineVersionSelector
                pipelineId={pipelineId}
                selectedPipelineVersionId={selectedPipelineVersionId}
                onSelectPipelineVersion={(pipelineVersion) => {
                  setSelectedPipelineVersionId(pipelineVersion.id);
                }}
              />
            </Flex>
          </Sider>
        ) : (
          <Sider
            theme="light"
            width={240}
            style={{ borderRight: `1px solid ${token.colorBorder}` }}
          >
            <Flex direction="column" align="stretch" style={{ height: "100%" }}>
              <Flex
                direction="row"
                justify="between"
                align="center"
                style={{
                  padding: 16,
                  backgroundColor: token.colorBgContainer,
                  borderBottom: `1px solid ${token.colorBorder}`,
                }}
              >
                <span
                  style={{
                    color: "black",
                    fontSize: 14,
                    fontWeight: 500,
                  }}
                >
                  <ShareAltOutlined rotate={90} />
                  &nbsp; Pipelines
                </span>
                <Button
                  type="primary"
                  onClick={() => {
                    setVisibleCreator(true);
                  }}
                  icon={<PlusOutlined />}
                ></Button>
              </Flex>
              <Flex
                style={{
                  borderBottom: "1px solid #F0F0F0",
                  boxShadow: "0 4px 4px -4px #eee",
                }}
              >
                <Form form={searchForm} style={{ padding: 8 }}>
                  <Form.Item name="search" noStyle>
                    <Input.Search
                      placeholder="Search by name"
                      allowClear
                      onSearch={(value) => {
                        setSearch(value);
                      }}
                    />
                  </Form.Item>
                </Form>
              </Flex>
              <div
                id="scrollableDiv"
                ref={scrollDivRef}
                style={{
                  flex: 1,
                  overflow: "auto",
                }}
              >
                <Suspense fallback={<PipelineInfiniteListFallback />}>
                  <PipelineInfiniteList
                    pipelineQueryRef={pipelineQueryRef}
                    selectedPipelineId={pipelineId}
                    onSelectPipeline={(pipeline) => {
                      //remove version id when selectedPipelineId changed
                      setSelectedPipelineVersionId(undefined);
                      navigate(`/pipelines/${pipeline.id}`);
                    }}
                    queryVariables={queryVariables}
                  />
                </Suspense>
              </div>
            </Flex>
          </Sider>
        )}

        <Layout>
          <Content style={{ position: "relative" }}>
            {pipelineId ? (
              selectedPipelineVersionId === "NONE" ? (
                <Flex
                  justify="center"
                  align="center"
                  style={{ height: "100%", padding: token.paddingMD }}
                >
                  <Alert
                    style={{
                      textAlign: "left",
                      backgroundColor: "transparent",
                      border: `1px ${token.colorBorder} solid`,
                    }}
                    message="You can select a pipeline version from the left sidebar."
                    description="The pipeline version is a readonly snapshot of the pipeline at a specific time. When you want to rollback to a previous version, you can select a version from the left sidebar and click the 'Restore' button."
                    type="info"
                    showIcon
                    icon={
                      <InfoCircleOutlined style={{ color: token.colorText }} />
                    }
                  />
                </Flex>
              ) : (
                <Suspense fallback={<FlexActivityIndicator />}>
                  <PipelineDetail pipelineId={pipelineId} />
                </Suspense>
              )
            ) : (
              <Flex justify="center" align="center" style={{ height: "100%" }}>
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={<span>No pipeline selected</span>}
                >
                  <Alert
                    style={{
                      textAlign: "left",
                      backgroundColor: "transparent",
                      border: `1px ${token.colorBorder} solid`,
                    }}
                    message="Pipeline is a set of tasks that are executed in a specific order."
                    description="You can create a pipeline by clicking the '+Create' button on the top left."
                    type="info"
                    showIcon
                    icon={
                      <InfoCircleOutlined style={{ color: token.colorText }} />
                    }
                  />
                </Empty>
              </Flex>
            )}
          </Content>
        </Layout>
        <PipelineEditorModal
          open={visibleCreator}
          onRequestClose={(pipelineId, type) => {
            setVisibleCreator(false);
            if (type === "create" && pipelineId) {
              setSearch("");
              searchForm.setFieldValue("search", "");
              navigate(`/pipelines/${pipelineId}`);
            }
          }}
          connections={[
            ConnectionHandler.getConnectionID(
              "root",
              "PipelineList_pipelines",
              {
                filter: defaultQueryFilter,
                order: ["LAST_MODIFIED_DESC"],
              }
            ),
          ]}
        />
      </Layout>
    </Layout>
  );
};

export default PipelineList;
