import { CopyOutlined } from "@ant-design/icons";
import Button from "antd/es/button";
import Switch from "antd/es/switch";
import Typography from "antd/es/typography";
import message from "antd/lib/message";
import { ModalProps } from "antd/lib/modal";
import graphql from "babel-plugin-relay/macro";
import { LucideDownload } from "lucide-react";
import React, { useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { useLazyLoadQuery } from "react-relay";

import { downloadBlob, from_global_id } from "../helpers";
import Flex from "./Flex";
import SyntaxHighlighterModal from "./SyntaxHighlighterModal";
import { PipelineJobYamlCodeViewerModalQuery } from "./__generated__/PipelineJobYamlCodeViewerModalQuery.graphql";

interface Props extends ModalProps {
  pipelineJobId: string;
}

const PipelineJobYamlCodeViewerModal: React.FC<Props> = ({
  pipelineJobId,
  ...props
}) => {
  const [fetchKey, setFetchKey] = useState(0);
  const [overrideOneShot, setOverrideOneShot] = useState(false);
  const { pipelineJob } = useLazyLoadQuery<PipelineJobYamlCodeViewerModalQuery>(
    graphql`
      query PipelineJobYamlCodeViewerModalQuery($id: ID!, $override: Boolean) {
        pipelineJob(id: $id) {
          yaml(override: $override)
        }
      }
    `,
    {
      id: pipelineJobId,
      override: overrideOneShot,
    },
    {
      fetchKey: `${fetchKey}`,
      fetchPolicy: "store-and-network",
    }
  );
  const rawPipelineJobId = from_global_id(pipelineJobId)[1];
  const pipelineJobYaml = pipelineJob?.yaml ?? "";
  return (
    <SyntaxHighlighterModal
      title={
        <Flex
          direction="row"
          align="center"
          justify="between"
          style={{ marginRight: 32 }}
        >
          <Typography.Title
            level={5}
          >{`${rawPipelineJobId}.yaml`}</Typography.Title>
          <Flex direction="row" align="center" justify="center" gap={"xs"}>
            <Typography.Text>Apply one-shot envs</Typography.Text>
            <Switch
              checkedChildren={"on"}
              unCheckedChildren={"off"}
              checked={overrideOneShot}
              onChange={setOverrideOneShot}
            />
            <Button
              icon={<LucideDownload />}
              size={"small"}
              onClick={() => {
                downloadBlob(
                  new Blob([pipelineJobYaml], {
                    type: "application/yaml",
                  }),
                  `${rawPipelineJobId}.yaml`
                );
              }}
              disabled={pipelineJob === null}
            >
              Download
            </Button>
            <CopyToClipboard
              text={pipelineJobYaml}
              onCopy={() => message.success("Copied to clipboard.")}
            >
              <Button icon={<CopyOutlined />} size={"small"} />
            </CopyToClipboard>
          </Flex>
        </Flex>
      }
      width={1000}
      code={pipelineJobYaml}
      language={"yaml"}
      {...props}
    />
  );
};

export default PipelineJobYamlCodeViewerModal;
