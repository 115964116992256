import {
  ClockCircleOutlined,
  ExclamationCircleOutlined,
  MinusCircleOutlined,
  StopOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import Tag from "antd/es/tag";
import theme from "antd/lib/theme";
import graphql from "babel-plugin-relay/macro";
import React from "react";
import { useFragment } from "react-relay";

import Flex from "./Flex";
import { TaskInstanceStatusTag_taskInstance$key } from "./__generated__/TaskInstanceStatusTag_taskInstance.graphql";

const TaskInstanceStatusTag: React.FC<{
  taskInstanceFragment: TaskInstanceStatusTag_taskInstance$key;
}> = ({ taskInstanceFragment }) => {
  const { token } = theme.useToken();
  const taskInstance = useFragment(
    graphql`
      fragment TaskInstanceStatusTag_taskInstance on TaskInstance {
        status
        statusInfo
      }
    `,
    taskInstanceFragment
  );
  return (
    <Flex direction="column" align="center" justify="center" gap={"xxs"}>
      {taskInstance.status === "RUNNING" ? (
        <div style={{ color: token.colorSuccess }}>
          <SyncOutlined spin /> {taskInstance.status}
        </div>
      ) : taskInstance.status === "TERMINATED" ? (
        <div style={{ color: "gray" }}>
          <MinusCircleOutlined /> {taskInstance.status}
        </div>
      ) : taskInstance.status === "CANCELLED" ? (
        <div style={{ color: "gray" }}>
          <StopOutlined /> {taskInstance.status}
        </div>
      ) : taskInstance.status === "ERROR" ? (
        <div style={{ color: token.colorError }}>
          <ExclamationCircleOutlined /> {taskInstance.status}
        </div>
      ) : (
        <div style={{ color: "gray" }}>
          <ClockCircleOutlined /> {taskInstance.status}
        </div>
      )}
      {taskInstance.status === "PENDING" && taskInstance.statusInfo && (
        <Tag
          style={{ backgroundColor: token.colorErrorBg, marginInlineEnd: 0 }}
        >
          {taskInstance.statusInfo}
        </Tag>
      )}
    </Flex>
  );
};

export default TaskInstanceStatusTag;
