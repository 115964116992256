import { ExclamationCircleFilled } from "@ant-design/icons";
import Form from "antd/es/form";
import Input from "antd/es/input";
import Popconfirm, { PopconfirmProps } from "antd/es/popconfirm";
import Typography from "antd/es/typography";
import _ from "lodash";
import React from "react";

import Flex from "./Flex";
import WhiteSpace from "./WhiteSpace";

const { Text } = Typography;

interface Props extends PopconfirmProps {
  confirmText: string;
  content: React.ReactNode;
  title: React.ReactNode;
}
const PopConfirmWithInput: React.FC<Props> = ({
  confirmText,
  children,
  onConfirm,
  onCancel,
  title,
  icon,
  content,
  ...props
}) => {
  const [form] = Form.useForm();
  const typedText = Form.useWatch("confirmText", form);

  return (
    <Popconfirm
      title={
        <Flex
          direction="column"
          justify="start"
          align="start"
          style={{ maxWidth: 250 }}
        >
          <Text strong>
            {icon ?? (
              <ExclamationCircleFilled
                style={{ color: "#faad14", marginRight: 5 }}
              />
            )}
            {title}
          </Text>
          <WhiteSpace direction="column" />
          <Text>{content}</Text>
          <WhiteSpace direction="column" />
          <Text>
            {" "}
            Please type <Text code>{confirmText}</Text> to confirm.
          </Text>
          <Form form={form} style={{ width: "100%" }}>
            <Form.Item
              name="confirmText"
              rules={[
                {
                  required: true,
                  message: `Please type ${confirmText} to confirm.`,
                  validator: (_, value) => {
                    if (value === confirmText) {
                      return Promise.resolve();
                    }
                    return Promise.reject();
                  },
                },
              ]}
            >
              <Input
                size="small"
                autoFocus
                onClick={(e) => {
                  e.stopPropagation();
                }}
                placeholder={confirmText}
              />
            </Form.Item>
          </Form>
        </Flex>
      }
      icon={null}
      okButtonProps={{ disabled: confirmText !== typedText, danger: true }}
      onConfirm={(e) => {
        form.resetFields();
        _.isFunction(onConfirm) && onConfirm(e);
      }}
      onCancel={(e) => {
        form.resetFields();
        _.isFunction(onCancel) && onCancel(e);
      }}
      okText="Delete"
      cancelText="No"
      {...props}
    >
      {children}
    </Popconfirm>
  );
};

export default PopConfirmWithInput;
