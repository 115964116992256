import Button, { ButtonProps } from "antd/es/button";
import Modal from "antd/es/modal";
import Typography from "antd/es/typography";
import graphql from "babel-plugin-relay/macro";
import { LucideTrash2 } from "lucide-react";
import React, { useState } from "react";
import { useFragment, useMutation } from "react-relay";

import Flex from "./Flex";
import { PipelineJobDeleteButtonMutation } from "./__generated__/PipelineJobDeleteButtonMutation.graphql";
import { PipelineJobDeleteButton_pipelineJob$key } from "./__generated__/PipelineJobDeleteButton_pipelineJob.graphql";

const { Text } = Typography;

interface Props extends ButtonProps {
  pipelineJobFragment: PipelineJobDeleteButton_pipelineJob$key | null;
  onCompleted?: () => void;
}

const PipelineJobDeleteButton: React.FC<Props> = ({
  pipelineJobFragment,
  onCompleted,
  ...props
}) => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const pipelineJob = useFragment(
    graphql`
      fragment PipelineJobDeleteButton_pipelineJob on PipelineJob {
        id
      }
    `,
    pipelineJobFragment
  );
  const [commitDelete, isInFlightCommitDelete] =
    useMutation<PipelineJobDeleteButtonMutation>(graphql`
      mutation PipelineJobDeleteButtonMutation(
        $input: DeletePipelineJobInput!
      ) {
        deletePipelineJob(input: $input) {
          pipelineJob {
            id
          }
          error {
            __typename
            ... on UnauthenticatedError {
              message
            }
          }
        }
      }
    `);
  const tryDeletePipelineJob = () => {
    if (pipelineJob === null) {
      return;
    }
    commitDelete({
      variables: {
        input: {
          id: pipelineJob.id,
        },
      },
      onCompleted(response, errors) {
        onCompleted?.();
      },
    });
  };
  return (
    <>
      <Button
        type="text"
        icon={<LucideTrash2 />}
        size="small"
        onClick={() => setModalOpen(true)}
        danger
        {...props}
      >
        Delete
      </Button>
      <Modal
        open={modalOpen}
        styles={{
          body: {
            padding: 0,
          },
        }}
        onCancel={() => {
          setModalOpen(false);
        }}
        title={"Confirm deletion of pipeline job"}
        footer={
          <Flex direction="row" align="center" justify="between">
            <Button
              onClick={() => {
                setModalOpen(false);
              }}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              onClick={() => {
                tryDeletePipelineJob();
              }}
              disabled={isInFlightCommitDelete}
              loading={isInFlightCommitDelete}
              danger
            >
              Delete
            </Button>
          </Flex>
        }
      >
        <Text type="secondary">
          Are you sure you want to delete this pipeline job? This action cannot
          be undone and will permanently remove the job and all associated data.
          Please confirm to proceed or cancel to return to the previous screen.
        </Text>
      </Modal>
    </>
  );
};

export default PipelineJobDeleteButton;
