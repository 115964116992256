import { ReloadOutlined } from "@ant-design/icons";
import Button from "antd/es/button";
import Result from "antd/es/result";
import CryptoES from "crypto-es";
import { useAtomValue, useSetAtom } from "jotai";
import { useResetAtom } from "jotai/utils";
import React, { ReactElement, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";

import { HTTP_401_UNAUTHORIZED } from "../RelayEnvironment";
import { AuthenticationError } from "../errors";
import { generateRandomString, getWebServerEndpoint } from "../helpers";
import { STORAGE_KEY, authTokenAtom, projectIdAtom, userAtom } from "./atoms";
import { minifyGraphQLQuery } from "./backendai";

export const getFetchAuthHeaders = () => {
  const headers: {
    [key: string]: string;
  } = {
    "Content-Type": "application/json",
    "X-BackendAI-Version": "v6.20220615",
  };
  const ssoToken = localStorage.getItem(STORAGE_KEY.SSO_TOKEN);
  if (ssoToken !== null && ssoToken !== "null") {
    headers["X-BackendAI-SSO"] = ssoToken.replace(/^"(.*)"$/, "$1");
  }
  const sessionId = localStorage.getItem(STORAGE_KEY.SESSION_ID);
  if (sessionId !== null) {
    headers["X-BackendAI-SessionID"] = sessionId;
  }

  return headers;
};

const insertPathAt = (
  requestPath: string,
  index: number,
  pathSegment: string
): string => {
  const appendSlash = requestPath.endsWith("/");
  const requestPathSegments = requestPath.split("/").filter((sg) => sg !== "");
  requestPathSegments.splice(index, 0, pathSegment);
  let newRequestPath = requestPathSegments.join("/");
  if (appendSlash) {
    // NOTE: Append slash for a definitive URL
    // https://docs.djangoproject.com/en/dev/misc/design-philosophies/#definitive-urls
    newRequestPath = newRequestPath + "/";
  }
  return newRequestPath;
};

const buildProxyUrl = (requestPath: string): URL => {
  const isPipelineProxyRequest = requestPath.startsWith("/pipeline");
  const isHealthCheckRequest = requestPath
    .replace(/\/+$/, "")
    .endsWith("/health");

  if (isPipelineProxyRequest && !isHealthCheckRequest) {
    const isProductionEnv = process.env.NODE_ENV === "production";
    const isCloudEnv = process.env.REACT_APP_ENV === "cloud";

    if (isProductionEnv && !isCloudEnv) {
      // In the production release, "api" is inserted into the request path
      // after "/pipeline" to help Nginx differentiate between API requests
      // and static resource requests.
      requestPath = insertPathAt(requestPath, 1, "api");
    }
  }

  const baseUrl = getWebServerEndpoint() || "http://127.0.0.1:8090";
  return new URL(requestPath, baseUrl);
};

export function baiFetch(input: string, init?: RequestInit): Promise<Response> {
  const { headers, ...resetInit } = init || {};
  const proxyUrl = buildProxyUrl(input);
  return fetch(proxyUrl, {
    headers: {
      "Content-Type": "application/json",
      ...getFetchAuthHeaders(),
      ...headers,
    },
    mode: "cors",
    credentials: "include",
    ...resetInit,
  });
}

export const resetAuth = () => {
  localStorage.removeItem(STORAGE_KEY.SSO_TOKEN);
  localStorage.removeItem(STORAGE_KEY.SESSION_ID);
};

interface LoginStatus {
  authenticated: boolean;
  data: {
    access_key: string;
    role: string;
    status: string;
  };
  session_id: string;
}

interface AuthContextType {
  user: any | null;
  login: (callback: VoidFunction) => Promise<void>;
  logout: (callback?: VoidFunction) => void;
  isInFlightLogin: boolean;
  isInFlightLogout: boolean;
}

// eslint-disable-next-line
const AuthContext = React.createContext<AuthContextType>(null!);

interface AuthProviderProps {
  children: ReactElement;
  onLogin: VoidFunction;
  onLogout: VoidFunction;
  onInvalidToken: VoidFunction;
  invalidTokenFallback?: ReactElement;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({
  children,
  onLogin,
  onLogout,
  onInvalidToken,
  invalidTokenFallback,
}) => {
  const [isInFlightLogout, setIsInFlightLogout] = useState(false);
  const [isInFlightLogin, setIsInFlightLogin] = useState(false);

  const resetCurrentProjectId = useResetAtom(projectIdAtom);
  const user = useAtomValue(userAtom);
  const setAuthToken = useSetAtom(authTokenAtom);

  const login: AuthContextType["login"] = async (callback) => {
    setIsInFlightLogin(true);
    return baiFetch("/server/login-check", {
      method: "POST",
    })
      .then((r) => r.json())
      .then(async (response: LoginStatus) => {
        if (!response.authenticated) {
          // TODO: Login Webserver
          throw new AuthenticationError("Login failed", {
            description: "Please log in to Backend.AI Web.",
          });
        }

        // GraphQL
        const userQuery = `
          query {
            user {
              email
            }
          }
        `;
        const payload: {
          data: {
            user: {
              email: string;
            };
          };
        } = await baiFetch("/func/admin/gql", {
          method: "POST",
          body: JSON.stringify({
            query: minifyGraphQLQuery(userQuery),
          }),
        }).then((r) => r.json());

        localStorage.setItem(STORAGE_KEY.SESSION_ID, response.session_id);

        return baiFetch("/pipeline/login/", {
          method: "POST",
          body: JSON.stringify({
            ...payload.data.user,
          }),
        });
      })
      .then(async (r) => {
        const authResponse = await r.json();
        if (r.status === HTTP_401_UNAUTHORIZED) {
          resetAuth();
          // TODO: Dispatch
          throw new AuthenticationError("Login failed", {
            description: authResponse.msg,
          });
        }
        if (authResponse.token) {
          onLogin();
          setAuthToken(authResponse.token);
          callback();
          resetCurrentProjectId();
        }
      })
      .finally(() => {
        setIsInFlightLogin(false);
      });
  };

  const logout = (callback?: VoidFunction) => {
    setIsInFlightLogout(true);
    setAuthToken(null);
    resetAuth();
    callback?.();
    onLogout();
    setIsInFlightLogout(false);
  };

  const value = {
    user,
    login,
    logout,
    isInFlightLogin,
    isInFlightLogout,
  };

  return (
    <ErrorBoundary
      onError={(error, info) => {
        if (error.message === "InvalidSessionToken") {
          resetAuth();
          onInvalidToken();
        }
      }}
      fallbackRender={({ error, resetErrorBoundary }) => {
        return (
          <Result
            status="warning"
            title="There is a problem with your request."
            extra={
              <Button
                type="primary"
                key="console"
                onClick={() => {
                  resetErrorBoundary();
                  window.location.href = "/";
                }}
                icon={<ReloadOutlined />}
              >
                Reload the page
              </Button>
            }
          >
            <div className="desc">
              <h3>Error Details</h3>
              <p>{error.message}</p>
            </div>
          </Result>
        );
      }}
    >
      <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
    </ErrorBoundary>
  );
};

export function useAuth() {
  return React.useContext(AuthContext);
}

function getEncodedPayload(body: string) {
  const iv = generateRandomString(16);
  const endpoint = getWebServerEndpoint() || "";
  const key = (btoa(endpoint) + iv + iv).substring(0, 32);
  const result = CryptoES.AES.encrypt(body, CryptoES.enc.Utf8.parse(key), {
    iv: CryptoES.enc.Utf8.parse(iv),
    padding: CryptoES.pad.Pkcs7,
    mode: CryptoES.mode.CBC,
  });
  return iv + ":" + result.toString();
}
