/**
 * @generated SignedSource<<26245a0ec5880ad774f7cbba4025dbcd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SetPipelineJobPriorityInput = {
  clientMutationId?: string | null;
  id: string;
  priority: number;
};
export type PipelineJobDetailSetPipelineJobPriorityMutation$variables = {
  input: SetPipelineJobPriorityInput;
};
export type PipelineJobDetailSetPipelineJobPriorityMutation$data = {
  readonly setPipelineJobPriority: {
    readonly pipelineJob: {
      readonly id: string;
      readonly taskinstanceSet: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly id: string;
            readonly priority: number;
          } | null;
        } | null>;
      };
    } | null;
  } | null;
};
export type PipelineJobDetailSetPipelineJobPriorityMutation = {
  response: PipelineJobDetailSetPipelineJobPriorityMutation$data;
  variables: PipelineJobDetailSetPipelineJobPriorityMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "SetPipelineJobPriorityPayload",
    "kind": "LinkedField",
    "name": "setPipelineJobPriority",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "PipelineJob",
        "kind": "LinkedField",
        "name": "pipelineJob",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "TaskInstanceConnection",
            "kind": "LinkedField",
            "name": "taskinstanceSet",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "TaskInstanceEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TaskInstance",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "priority",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PipelineJobDetailSetPipelineJobPriorityMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PipelineJobDetailSetPipelineJobPriorityMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "0e3f7eaf95c9c0132c3a911d3eafb22f",
    "id": null,
    "metadata": {},
    "name": "PipelineJobDetailSetPipelineJobPriorityMutation",
    "operationKind": "mutation",
    "text": "mutation PipelineJobDetailSetPipelineJobPriorityMutation(\n  $input: SetPipelineJobPriorityInput!\n) {\n  setPipelineJobPriority(input: $input) {\n    pipelineJob {\n      id\n      taskinstanceSet {\n        edges {\n          node {\n            id\n            priority\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "3d85a7b3b28d011e5b5868eccd59feb3";

export default node;
