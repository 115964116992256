import Alert from "antd/es/alert";
import Button from "antd/es/button";
import Card from "antd/es/card";
import Divider from "antd/es/divider";
import Form from "antd/es/form";
import Grid from "antd/es/grid";
import Image from "antd/es/image";
import Input from "antd/es/input";
import Typography from "antd/es/typography";
import Link from "antd/es/typography/Link";
import notification from "antd/lib/notification";
import theme from "antd/lib/theme";
import { LogInIcon } from "lucide-react";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { BooleanParam, useQueryParam } from "use-query-params";

import Flex from "../components/Flex";
import WhiteSpace from "../components/WhiteSpace";
import { AuthenticationError, ConfigurationError } from "../errors";
import { getWebServerEndpoint } from "../helpers";
import { STORAGE_KEY } from "../hooks/atoms";
import { useAuth } from "../hooks/auth";

const { Text } = Typography;

const REDIRECT_QUERY_PARAM = "redirect";

const Login: React.FC = () => {
  const { token } = theme.useToken();
  const screens = Grid.useBreakpoint();
  const { login, isInFlightLogin } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const state = location.state as {
    from?: {
      pathname?: string;
    };
  };
  const from = state?.from?.pathname || "/";

  const [form] = Form.useForm();

  const tryLogin = () => {
    const endpoint = form.getFieldValue("endpoint") ?? getWebServerEndpoint();
    const endpointNotEndsWith = endpoint?.endsWith("/")
      ? endpoint.slice(0, -1)
      : endpoint;
    localStorage.removeItem(STORAGE_KEY.SESSION_ID);
    localStorage.setItem("Backend_AI_FastTrack.endpoint", endpointNotEndsWith);
    login(() => {
      navigate(from, { replace: true });
    }).catch((e: Error) => {
      if (e instanceof AuthenticationError) {
        notification.error(e);
      } else if (e instanceof ConfigurationError) {
        notification.error(e);
      } else {
        notification.error({
          message: "Login failed",
          description: `Failed to log in to ${getWebServerEndpoint()}`,
        });
      }
    });
  };

  const [isRedirect] = useQueryParam(REDIRECT_QUERY_PARAM, BooleanParam);

  useEffect(() => {
    if (isRedirect === true) {
      tryLogin();
    }
  }, [isRedirect]);

  const onFinish = (values: { endpoint: string }) => {
    const baseWebserverURL = new URL(
      window.__RUNTIME_CONFIG__.FRONTEND_RUNTIME_WEBSERVER_ENDPOINT
    );
    const interactiveLoginURL = new URL("/interactive-login", baseWebserverURL);
    const callbackURL = new URL(window.location.href);
    callbackURL.searchParams.set(REDIRECT_QUERY_PARAM, "1");
    interactiveLoginURL.searchParams.set("name", "Backend.AI FastTrack");
    interactiveLoginURL.searchParams.set("callback", callbackURL.href);
    window.location.replace(interactiveLoginURL.href);
  };

  const styles: { [key: string]: React.CSSProperties } = {
    container: {
      margin: "0 auto",
      padding: screens.md
        ? `${token.paddingXL}px`
        : `${token.sizeXXL}px ${token.padding}px`,
      width: "480px",
    },
    footer: {
      marginTop: token.marginLG,
      textAlign: "center",
      width: "100%",
    },
    header: {
      marginBottom: token.marginXL,
    },
    section: {
      alignItems: "center",
      background: "#D6EDF5CC",
      display: "flex",
      height: "100vh",
      padding: screens.md ? `${token.sizeXXL}px 0px` : "0px",
    },
    text: {
      color: token.colorTextSecondary,
    },
  };

  return (
    <section style={styles.section}>
      <div style={styles.container}>
        <Card
          style={{ width: "100%", height: 480, boxSizing: "border-box" }}
          bordered={false}
        >
          <div style={styles.header}>
            <Flex
              direction="column"
              align="center"
              justify="start"
              style={{ overflow: "visible" }}
            >
              <Image
                src={"/static/manifest/backend.ai-fasttrack-text-logo.svg"}
                style={{ width: 218.56, height: 65 }}
                alt={"Backend.AI FastTrack Logo"}
                preview={false}
              />
              <Text
                style={{
                  fontSize: 24,
                  lineHeight: "16px",
                  fontWeight: 200,
                  whiteSpace: "nowrap",
                }}
              >
                {window.__RUNTIME_CONFIG__.FRONTEND_RUNTIME_LOGIN_TITLE && (
                  <span
                    dangerouslySetInnerHTML={{
                      __html: decodeURIComponent(
                        window.__RUNTIME_CONFIG__.FRONTEND_RUNTIME_LOGIN_TITLE
                      ),
                    }}
                  />
                )}
              </Text>
            </Flex>
          </div>
          <WhiteSpace direction="column" size="xl" />
          <Alert
            type="info"
            message={
              "You can log in to Backend.AI FastTrack with Backend.AI account."
            }
            closable={false}
            showIcon
          />
          <WhiteSpace direction="column" size="xl" />
          <Form
            form={form}
            initialValues={{
              endpoint: getWebServerEndpoint(),
            }}
            onFinish={onFinish}
            disabled={isInFlightLogin}
          >
            <Form.Item>
              <Flex direction="row" align="center" justify="center">
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ width: "80%" }}
                  icon={<LogInIcon color={token.colorWhite} />}
                  size="large"
                  loading={isInFlightLogin}
                >
                  Log in with Backend.AI
                </Button>
              </Flex>
            </Form.Item>
            <Form.Item
              hidden={
                !!window.__RUNTIME_CONFIG__.FRONTEND_RUNTIME_WEBSERVER_ENDPOINT
              }
              name="endpoint"
              label="Backend.AI Endpoint"
              rules={[
                {
                  type: "url",
                  warningOnly: true,
                },
                {
                  required: true,
                },
              ]}
              extra={"e.g. http://127.0.0.1:8090"}
            >
              <Input />
            </Form.Item>
            {process.env.NODE_ENV === "development" ||
            process.env.REACT_APP_ENV === "cloud" ? (
              <div style={styles.footer}>
                <Text style={styles.text}>Don't have an account?</Text>{" "}
                <Link href={form.getFieldValue("endpoint")}>Sign up now</Link>
              </div>
            ) : null}
          </Form>
          <Flex direction="column" style={styles.footer}>
            <Divider />
            <Flex direction="row" align="center" justify="center">
              <Text style={styles.text}>
                Powered by{" "}
                <Link href={"https://backend.ai"} target="_blank">
                  <Image
                    src="/static/manifest/backend.ai-text-logo.svg"
                    style={{
                      width: 96,
                      height: 24,
                      paddingLeft: 2,
                      paddingBottom: 4,
                    }}
                    preview={false}
                  />
                </Link>
              </Text>
            </Flex>
          </Flex>
        </Card>
      </div>
    </section>
  );
};

export default Login;
