import { Node } from "reactflow";
import { createMachine } from "xstate";

import { PipelineTaskFormInput } from "../components/PipelineTaskForm";
import { TaskNodeData } from "../components/PipelineYamlEditor";

export interface PipelineTaskEditorContext {
  x: number;
  y: number;
}

export const pipelineTaskEditor =
  /** @xstate-layout N4IgpgJg5mDOIC5QDMA2B7A7gUQgSwBd0AnAOjwlTAGIA5AeQBFsB9RgJQEEBxFgZQAqndgIDaABgC6iUAAd0sQnnQA7GSAAeiALQAWAJz7SAdgAcARgBMAVgA0IAJ6JzR08YDM43QDY3Bw4buAL5B9mhYuIQk5JQ0HPQACiy02ADqyUzYEtJIIPKKBMpquVoI2ubivqSWuua1Fr7e3taW9k4IlsbWpLrixhWW3ma61tbBoSDhOPhEZBRU1ADCnLSL2AAy2er5Sqrqpdqm3rqk+tYu-f1W3oNtOubW4qTi+n3W+kPilqYf42EY0yiZAgxAAhlAoHgVFBaOgIGAAEJgZAkMAAWXQADcaAxmGwuNwtrkdoU9iUdJZxOZSI8Pu9XhZzKZ3HZHM5XB4vL5jP4An9JgDIrNSCDwZDobD4UiUcR0VicZl8Tx+AJEkS5ApdsVQAd3OZjKRTN8rKz2sYnoZvC93F0Qv8IjNoqKIVCYXCFXiOMrBGqpNtNaTtZodOdSL5rLp+kNrN4PpHvHcED4jDZ3K8btY7QKHUDSELXZK4htsAJWLisn7iQGivsdE0Tmn4-pjMdzENTInzJ5SO4jWdxPT3CN9JYs1MhdF8xL3dQ+JwAGoVnIago18llfruU7GGzeC7mAbGRPaXvPSm6SP6r4-Y5jwWOshTt3wpYrNabSsrrW1jddkyUh53BufQh10RMahOGod1MWovhbHlRwmccHzzGYC3dcgVCUUFUCWAAJFZuCXf1VzJHUKWsA1N3cTpIypGNLHcRNPCeJoW0MXpzk6Uw7xzYVnXFZ9EQAV2QZAwGIOhFS9XgfQSdU8mrMjgw3A0bF0GwI2MECRyNRNRh6fRTC5PwAkMXjAX4sEXWnKVRPEySNFgAhQQIMBSFBZA3OIAAKCpxAASmoZDcwE9C7LEiSFJJNdyLKOonhqSwmS6TtujOPUbAsicyE87zOAgfAJTATBC2oJyXLcjyvIknzAuC+9czyiSCqKmESsLaKlKDA4xhOEcxl8ODYw7NkECA6lxCHNsB2Sqxe0zLMVHdeBchC2YSO-ddygPbxqlqeo2yOZpWjG7RGINd43g+c1vl+bKUPmMBNsDH9yi8LddBoi8uWuW4zoeGk+nNbwbTpL6eKQxqrLFcLEWRVEMWxF7YpUvQBxpDNjw8ExjA8L7bShvinWswTCxR5TSkjHtmiHcQXiZBpTR0C8TAsLKicsyc0Ns56q1InqdDBsM8Yh7SjXZpixrbPa23Y-ROKsMwHtzJ9C0w7DUApwWyncPURZ5XtxeNRjmPNZ4ONB-Q6n1Dn7S5x8eaE0gAGMAAtQWhSBtbelwtxuJpKIjCX5rNp4XgVq2bZ3Rb7ZykVSbhhF7Ikn3toPbpxCOTkznNTLmfigww0ZuMYJtWPswd6r8sKgsOvdNO4u0YYw10YyHgV+Dm0TXo9rTZkmhGyM0xCEIgA */
  createMachine({
    schema: {
      context: {} as PipelineTaskEditorContext,
      events: {} as
        | {
            type: "DROP_NEW_NODE";
            x: number;
            y: number;
            nodeType: string;
          }
        | {
            type: "NODE_DRAG_START";
          }
        | {
            type: "NODE_DRAG_STOP";
          }
        | {
            type: "NODE_DRAG";
          }
        | {
            type: "SAVE";
            values: PipelineTaskFormInput;
          }
        | {
            type: "CANCEL";
          }
        | {
            type: "CHANGE";
          }
        | {
            type: "DELETE_NODE";
            node?: Node<TaskNodeData>;
          }
        | {
            type: "DUPLICATE_NODE";
            node?: Node<TaskNodeData>;
          }
        | {
            type: "TOGGLE_SKIP";
            node?: Node<TaskNodeData>;
          }
        | {
            type: "UPDATE_SUCCEED";
          }
        | {
            type: "UPDATE_FAIL";
          },
    },
    id: "flowEditor",
    initial: "idle",
    states: {
      idle: {
        entry: "resetForm",
        on: {
          NODE_DRAG_START: {
            target: "draggingNodeBeforeMove",
          },
          DROP_NEW_NODE: {
            target: "afterAddingNewNode",
            actions: "addTempNode",
          },
          UPDATE_FAIL: {
            target: "idle",
            actions: "removeTempNode",
          },
          DELETE_NODE: {
            target: "idle",
            actions: "deleteNode",
          },
          DUPLICATE_NODE: {
            target: "idle",
            actions: "duplicateNode",
          },
          TOGGLE_SKIP: {
            target: "pending",
            actions: "toggleSkip",
          },
        },
      },
      draggingNodeBeforeMove: {
        on: {
          NODE_DRAG: {
            target: "draggingNodeBuffer",
          },
          NODE_DRAG_STOP: {
            target: "EditingNode",
          },
        },
      },
      draggingNode: {
        on: {
          NODE_DRAG_STOP: {
            target: "idle",
            actions: "saveNodePositions",
          },
        },
      },
      EditingNode: {
        entry: "setFieldsUsingSelectedNode",
        initial: "initial",
        states: {
          initial: {
            on: {
              CHANGE: {
                target: "changed",
              },
            },
          },
          changed: {},
        },
        on: {
          DELETE_NODE: {
            target: "idle",
            actions: "deleteNode",
          },
          SAVE: {
            target: "idle",
            actions: "saveFormValue",
          },
          CANCEL: {
            target: "idle",
            actions: "removeTempNode",
          },
        },
      },
      draggingNodeBuffer: {
        after: {
          "100": {
            target: "#flowEditor.draggingNode",
            actions: [],
          },
        },
        on: {
          NODE_DRAG_STOP: {
            target: "EditingNode",
          },
        },
      },
      afterAddingNewNode: {
        after: {
          "0": {
            target: "#flowEditor.EditingNode",
            actions: [],
          },
        },
      },
      pending: {
        on: {
          UPDATE_SUCCEED: {
            target: "idle",
          },
          UPDATE_FAIL: {
            target: "idle",
          },
        },
      },
    },
  });
