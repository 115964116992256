import InputNumber from "antd/es/input-number";
import Modal, { ModalProps } from "antd/es/modal";
import React, { useState } from "react";

import Flex from "./Flex";

interface PrioritySettingModalProps extends ModalProps {
  onSetting: (priority: number) => Promise<void>;
  onRequestClose: () => void;
  title: React.ReactNode;
  warning?: React.ReactNode;
  defaultValue?: number;
}

const PrioritySettingModal: React.FC<PrioritySettingModalProps> = ({
  onSetting,
  onRequestClose,
  title,
  warning,
  defaultValue,
  ...props
}) => {
  const [priority, setPriority] = useState<number>(defaultValue ?? 10);

  const handleOk = async () => {
    await onSetting(priority);
    onRequestClose();
  };

  return (
    <Modal
      title={title}
      onOk={handleOk}
      onCancel={() => {
        onRequestClose();
      }}
      destroyOnClose
      {...props}
    >
      <Flex direction="column" align="stretch" gap={"xs"}>
        {warning && warning}
        <InputNumber
          addonBefore={"Priority"}
          min={0}
          max={100}
          defaultValue={defaultValue ?? 10}
          onChange={(value) => {
            if (value !== null) {
              setPriority(value);
            }
          }}
          style={{ width: "100%" }}
        />
      </Flex>
    </Modal>
  );
};

export default PrioritySettingModal;
