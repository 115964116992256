import * as colors from "@ant-design/colors";
import Color from "color";
import _ from "lodash";

export const cursor_to_offset = (cursor: string): number | undefined => {
  const x = _.nth(window.atob(cursor).split(":"), 1);
  return x ? parseInt(x, 10) : undefined;
};

export const offset_to_cursor = (offset: number): string => {
  return window.btoa(`arrayconnection:${offset}`);
};

export const from_global_id = (global_id: string): string[] => {
  return atob(global_id).split(":");
};

// from antd/lib/_util/colors.d.ts

export const statusColors = [
  "pink",
  "red",
  "yellow",
  "orange",
  "cyan",
  "green",
  "blue",
  "purple",
  "geekblue",
  "magenta",
  "volcano",
  "gold",
  "lime",
] as const;
export type PresetColorType = (typeof statusColors)[number];
export const getStatusColor = (status: string) => {
  let color: PresetColorType | "white" | "#999" | "#666" | "#FF00FF66" =
    "white";
  switch (status) {
    case "RUNNING":
    case "RESTARTING":
    case "RESIZING":
    case "TERMINATING":
      color = "blue";
      break;
    case "ERROR":
      color = "red";
      break;
    case "CANCELLING":
    case "CANCELLED":
      color = "#999";
      break;
    case "WAITING":
    case "PAUSED":
    case "SUSPENDED":
    case "BUILDING":
    case "PULLING":
    case "SCHEDULED":
    case "PREPARING":
    case "PENDING":
      color = "gold";
      break;
    case "TERMINATED":
      color = "#666";
      break;
    case "SKIPPED":
      color = "#FF00FF66";
      break;
    default:
      color = "white";
      break;
  }
  return color;
};

export const getStatusTextColor = (status = "") => {
  const colorName = getStatusColor(status);
  const color = Color(colorName);

  if (_.keys(colors).includes(colorName)) {
    // eslint-disable-next-line
    //@ts-ignore
    return colors[colorName][7];
  } else {
    const bgColor = Color(getStatusBgColor(status));
    return bgColor.luminosity() < 0.6 ? "#fff" : color.darken(0.8).hex();
  }
};

export const getStatusBgColor = (status: string) => {
  const colorName = getStatusColor(status);
  const color = Color(colorName);

  if (_.keys(colors).includes(colorName)) {
    // eslint-disable-next-line
    //@ts-ignore
    return colors[colorName][0];
  } else {
    const bgColor = color.isDark() ? color.lighten(0.9) : color.lighten(0.5);
    return bgColor.hex();
  }
};

export const getResultColor = (result: string) => {
  let color = "lightgrey";
  switch (result) {
    case "SUCCESS":
      color = "green";
      break;
    case "FAILURE":
      color = "red";
      break;
    case "UNDEFINED":
      color = "lightgrey";
      break;
    default:
      color = "white";
      break;
  }
  return color;
};

export const getWebServerEndpoint = () => {
  return (
    window.__RUNTIME_CONFIG__.FRONTEND_RUNTIME_WEBSERVER_ENDPOINT ||
    localStorage.getItem("Backend_AI_FastTrack.endpoint")
  );
};

export const isFinalStatus = (status?: string) => {
  return _.includes(["TERMINATED", "CANCELLED", "ERROR"], status);
};

export const numberMinRule = (min: number, message: string) => () => ({
  validator(form: any, value: string) {
    const numberValue = Number(value);
    if (numberValue < min) {
      return Promise.reject(message);
    }
    return Promise.resolve();
  },
});

export const numberMaxRule = (max: number, message: string) => () => ({
  validator(form: any, value: string) {
    const numberValue = Number(value);
    if (numberValue > max) {
      return Promise.reject(message);
    }
    return Promise.resolve();
  },
});

export function changeBinaryUnit(
  value: string,
  targetUnit: "b" | "k" | "m" | "g" | "t" | "p" | "auto" = "g",
  defaultUnit = "b"
): number {
  let sourceUnit;
  const binaryUnits = ["b", "k", "m", "g", "t", "p", "auto"];
  const bBinaryUnits = ["B", "KiB", "MiB", "GiB", "TiB", "PiB"];
  if (value.indexOf(" ") >= 0) {
    // Has string
    const v = value.split(/(\s+)/);
    if (bBinaryUnits.includes(v[2])) {
      value = v[0] + binaryUnits[bBinaryUnits.indexOf(v[2])];
    } else {
      value = v[0];
    }
  }
  if (binaryUnits.includes(value.slice(-1))) {
    sourceUnit = value.slice(-1);
    value = value.slice(0, -1);
  } else {
    sourceUnit = defaultUnit; // Fallback
  }
  // if (targetUnit === "auto") {
  // }

  return (
    parseInt(value) *
    Math.pow(
      1024,
      Math.floor(
        binaryUnits.indexOf(sourceUnit) - binaryUnits.indexOf(targetUnit)
      )
    )
  );
}

export const extractNonNullableNodesOnConnection = <
  T extends {
    readonly edges: readonly ({
      readonly node: {
        readonly id: string;
      } | null;
    } | null)[];
  },
>(
  conn: T | null
) => {
  if (!conn) {
    return [];
  }
  return _.filter(
    _.map(conn.edges, (e) => e?.node),
    (n) => n
  ) as NonNullable<NonNullable<NonNullable<T["edges"]>[0]>["node"]>[];
};

interface LocaleTimeUnit {
  second: string;
  minute: string;
  hour: string;
  day: string;
}

export const formatTime = (seconds: number, locale = "en"): string => {
  const localeTimeUnitMap: { [key: string]: LocaleTimeUnit } = {
    en: {
      second: "s",
      minute: "m",
      hour: "h",
      day: "d",
    },
    ko: {
      second: "초",
      minute: "분",
      hour: "시간",
      day: "일",
    },
  };
  const localeTimeUnit = localeTimeUnitMap[locale] ?? localeTimeUnitMap.en;
  if (seconds <= 0) {
    return `0${localeTimeUnit.second}`;
  }
  const days = Math.floor(seconds / 86400);
  seconds -= days * 86400;
  const hours = Math.floor(seconds / 3600);
  seconds -= hours * 3600;
  const minutes = Math.floor(seconds / 60);
  seconds -= minutes * 60;
  const timeUnits: string[] = [];
  if (days > 0) {
    timeUnits.push(`${days}${localeTimeUnit.day}`);
  }
  if (hours > 0) {
    timeUnits.push(`${hours}${localeTimeUnit.hour}`);
  }
  if (minutes > 0) {
    timeUnits.push(`${minutes}${localeTimeUnit.minute}`);
  }
  if (seconds > 0) {
    timeUnits.push(`${seconds}${localeTimeUnit.second}`);
  }
  return timeUnits.join(" ");
};

export enum PipelineJobStatus {
  PENDING = "PENDING",
  RUNNING = "RUNNING",
  WAITING = "WAITING",
  PAUSED = "PAUSED",
  TERMINATED = "TERMINATED",
  ERROR = "ERROR",
  CANCELLING = "CANCELLING",
  CANCELLED = "CANCELLED",
}

export function generateRandomString(length: number) {
  let text = "";
  const possible =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  for (let i = 0; i < length; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }
  return text;
}

export function downloadBlob(blob: Blob, filename: string) {
  const url = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = filename;
  a.click();
  URL.revokeObjectURL(url);
}
