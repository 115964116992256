import Button, { ButtonProps } from "antd/es/button";
import graphql from "babel-plugin-relay/macro";
import { LucideArchiveRestore } from "lucide-react";
import React from "react";
import { useFragment, useMutation } from "react-relay";

import { PipelineJobUnarchiveButtonMutation } from "./__generated__/PipelineJobUnarchiveButtonMutation.graphql";
import { PipelineJobUnarchiveButton_pipelineJob$key } from "./__generated__/PipelineJobUnarchiveButton_pipelineJob.graphql";

interface Props extends ButtonProps {
  pipelineJobFragment: PipelineJobUnarchiveButton_pipelineJob$key | null;
  onCompleted?: () => void;
}

const PipelineJobUnarchiveButton: React.FC<Props> = ({
  pipelineJobFragment,
  onCompleted,
  ...props
}) => {
  const pipelineJob = useFragment(
    graphql`
      fragment PipelineJobUnarchiveButton_pipelineJob on PipelineJob {
        id
      }
    `,
    pipelineJobFragment
  );
  const [commitUnarchive, isInFlightCommitUnarchive] =
    useMutation<PipelineJobUnarchiveButtonMutation>(graphql`
      mutation PipelineJobUnarchiveButtonMutation(
        $input: UnarchivePipelineJobInput!
      ) {
        unarchivePipelineJob(input: $input) {
          pipelineJob {
            __typename
            ... on PipelineJob {
              id
            }
            ... on UnauthenticatedError {
              message
            }
          }
        }
      }
    `);
  const tryUnarchivePipelineJob = () => {
    if (pipelineJob === null) {
      return;
    }
    commitUnarchive({
      variables: {
        input: {
          id: pipelineJob.id,
        },
      },
      onCompleted(response, errors) {
        onCompleted?.();
      },
    });
  };
  return (
    <Button
      type="text"
      icon={<LucideArchiveRestore />}
      style={{ color: "#454545" }}
      size="small"
      disabled={isInFlightCommitUnarchive}
      loading={isInFlightCommitUnarchive}
      onClick={tryUnarchivePipelineJob}
      {...props}
    >
      Unarchive
    </Button>
  );
};

export default PipelineJobUnarchiveButton;
