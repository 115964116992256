import { DeleteOutlined, FolderOutlined } from "@ant-design/icons";
import Button from "antd/es/button";
import Tooltip from "antd/es/tooltip";
import Typography from "antd/es/typography";
import graphql from "babel-plugin-relay/macro";
import React from "react";
import { useFragment, useMutation } from "react-relay";

import PopConfirmWithInput from "./PopConfirmWithInput";
import {
  PipelineDeleteButtonDeleteMutation,
  PipelineDeleteButtonDeleteMutation$data,
} from "./__generated__/PipelineDeleteButtonDeleteMutation.graphql";
import { PipelineDeleteButtonFragment$key } from "./__generated__/PipelineDeleteButtonFragment.graphql";

const { Text } = Typography;

interface Props {
  pipelineFrgmt: PipelineDeleteButtonFragment$key | null;
  containerProps?: React.HTMLAttributes<HTMLDivElement>;
  connections?: readonly string[];
  onCompleted?: (response: PipelineDeleteButtonDeleteMutation$data) => void;
  onError?: (error: Error) => void;
}

const PipelineDeleteButton: React.FC<Props> = ({
  pipelineFrgmt,
  containerProps,
  connections = [],
  onCompleted,
  onError,
}) => {
  const pipeline = useFragment(
    graphql`
      fragment PipelineDeleteButtonFragment on Pipeline {
        id
        name
        storage
        scheduledTask {
          expires
        }
      }
    `,
    pipelineFrgmt
  );

  const [commitDelete, isInFlightCommitDelete] =
    useMutation<PipelineDeleteButtonDeleteMutation>(graphql`
      mutation PipelineDeleteButtonDeleteMutation(
        $input: DestroyPipelineInput!
        $connections: [ID!]!
      ) {
        destroyPipeline(input: $input) {
          pipeline {
            id @deleteEdge(connections: $connections)
          }
          error {
            __typename
            ... on UnauthenticatedError {
              message
            }
            ... on ScheduleExistsError {
              message
            }
          }
        }
      }
    `);

  const deletePipeline = () => {
    if (!pipeline) {
      return;
    }
    commitDelete({
      variables: {
        input: {
          id: pipeline.id,
        },
        connections,
      },
      updater(store) {
        for (const connectionID of connections) {
          const connection = store.get(connectionID);
          if (connection) {
            const totalCount = connection.getValue("totalCount");
            if (typeof totalCount === "number") {
              connection.setValue(totalCount - 1, "totalCount");
            }
          }
        }
      },
      onCompleted,
      onError,
    });
  };

  return (
    <div {...containerProps}>
      <PopConfirmWithInput
        title={"Are you absolutely sure?"}
        content={
          <Text>
            This action cannot be undone. This will permanently delete the
            pipeline and <br />
            <Text code>
              <FolderOutlined /> {JSON.parse(pipeline?.storage || "{}").name}
            </Text>{" "}
            dedicated folder.
          </Text>
        }
        confirmText={pipeline?.name ?? "delete"}
        onConfirm={deletePipeline}
        disabled={pipeline?.scheduledTask ? true : false}
      >
        <>
          {/* To avoid an uncaughted exception: Argument appears to not be a ReactComponent. Keys: forceAlign,forcePopupAlign */}
          <Tooltip
            title={
              pipeline?.scheduledTask
                ? "Before you cancel the schedule first, you can't delete the pipeline."
                : "Delete"
            }
          >
            <Button
              type="text"
              icon={<DeleteOutlined />}
              disabled={pipeline?.scheduledTask ? true : false}
              danger
              loading={isInFlightCommitDelete}
            />
          </Tooltip>
        </>
      </PopConfirmWithInput>
    </div>
  );
};

export default PipelineDeleteButton;
