import { ToolOutlined } from "@ant-design/icons";
import Collapse from "antd/es/collapse";
import Typography from "antd/es/typography";
import theme from "antd/lib/theme";
import { useAtom } from "jotai";
import React from "react";

import { activeKeyAtom } from "../hooks/atoms";
import Flex from "./Flex";
import PipelineTaskTemplate from "./PipelineTaskTemplate";
import WhiteSpace from "./WhiteSpace";

const { Text } = Typography;
const { Panel } = Collapse;

export type PipelineTask = "default";

interface Props {
  style?: React.CSSProperties;
  children?: React.ReactNode;
}

const PipelineTaskTemplates: React.FC<Props> = ({ style }) => {
  const [activeKey, setActiveKey] = useAtom(activeKeyAtom);
  const { token } = theme.useToken();

  const supportedPipelineTasks = [
    {
      type: "default",
      label: (
        <Flex direction="row">
          <ToolOutlined />
          <WhiteSpace direction="row" size="sm" />
          Custom Task
        </Flex>
      ),
      color: token.colorPrimary,
    },
  ] as const;

  return (
    <Collapse
      activeKey={activeKey}
      expandIconPosition="end"
      style={{
        backgroundColor: "#fafafa",
        ...style,
      }}
      onChange={setActiveKey}
    >
      <Panel
        header={
          <Flex justify="between" align="center">
            <Flex direction="row" align="center" gap={"xs"}>
              <Text style={{ fontWeight: 500 }}>Task templates</Text>
              <Text type="secondary">
                (Drag and drop the template box below onto the canvas.)
              </Text>
            </Flex>
          </Flex>
        }
        key="1"
      >
        <Flex
          direction="row"
          align="start"
          justify="start"
          // style={{ overflow: "scroll" }}
          gap={"sm"}
          wrap="wrap"
        >
          {supportedPipelineTasks.map(({ type, label, color }, index) => (
            <PipelineTaskTemplate
              key={index}
              taskType={type}
              label={label}
              color={color}
            />
          ))}
        </Flex>
      </Panel>
    </Collapse>
  );
};

export default PipelineTaskTemplates;
