import Button from "antd/es/button";
import Modal, { ModalProps } from "antd/es/modal";
import React from "react";
import SyntaxHighlighter, {
  SyntaxHighlighterProps,
} from "react-syntax-highlighter";
import { a11yDark } from "react-syntax-highlighter/dist/esm/styles/hljs";

interface Props extends ModalProps {
  language: string;
  code: string;
  highlighterProps?: Partial<SyntaxHighlighterProps>;
}
const SyntaxHighlighterModal: React.FC<Props> = ({
  language,
  code,
  highlighterProps,
  ...props
}) => {
  return (
    <Modal
      destroyOnClose
      footer={[<Button onClick={props.onCancel}>Close</Button>]}
      {...props}
    >
      <SyntaxHighlighter
        language={language}
        style={a11yDark}
        codeTagProps={{
          style: {
            fontFamily: "monospace",
          },
        }}
        showLineNumbers
        {...highlighterProps}
      >
        {code}
      </SyntaxHighlighter>
    </Modal>
  );
};

export default SyntaxHighlighterModal;
