/**
 * @generated SignedSource<<ef820cad04eb4e442bc1d98f398d3f80>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type TaskInstanceStatusChoices = "BUILDING" | "CANCELLED" | "ERROR" | "PENDING" | "PREPARING" | "PULLING" | "RESIZING" | "RESTARTING" | "RUNNING" | "SCHEDULED" | "SKIPPED" | "SUSPENDED" | "TERMINATED" | "TERMINATING" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type TaskInstanceStatusTag_taskInstance$data = {
  readonly status: TaskInstanceStatusChoices;
  readonly statusInfo: string | null;
  readonly " $fragmentType": "TaskInstanceStatusTag_taskInstance";
};
export type TaskInstanceStatusTag_taskInstance$key = {
  readonly " $data"?: TaskInstanceStatusTag_taskInstance$data;
  readonly " $fragmentSpreads": FragmentRefs<"TaskInstanceStatusTag_taskInstance">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TaskInstanceStatusTag_taskInstance",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "statusInfo",
      "storageKey": null
    }
  ],
  "type": "TaskInstance",
  "abstractKey": null
};

(node as any).hash = "3e95b0b458ee81933af983a828f1deeb";

export default node;
