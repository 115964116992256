import { CopyOutlined } from "@ant-design/icons";
import { useToggle } from "ahooks";
import Button, { ButtonProps } from "antd/es/button";
import Tooltip from "antd/es/tooltip";
import message from "antd/lib/message";
import { FileCodeIcon, LucideDownload } from "lucide-react";
import React from "react";
import CopyToClipboard from "react-copy-to-clipboard";

import { downloadBlob } from "../helpers";
import Flex from "./Flex";
import SyntaxHighlighterModal from "./SyntaxHighlighterModal";
import WhiteSpace from "./WhiteSpace";

type codeButtonTypes = "view" | "download" | "copy";
const CodeButtons: React.FC<{
  code: string;
  language: string;
  filename: string;
  modalTitle?: string;
  types?: codeButtonTypes[];
  buttonProps?: Pick<ButtonProps, "size" | "disabled">;
  downloadText?: string;
  viewText?: string;
  copyText?: string;
}> = ({
  code,
  language,
  filename,
  modalTitle,
  types = ["view", "download", "copy"],
  buttonProps = {},
  downloadText,
  viewText = "View",
  copyText,
}) => {
  const [visibleSyntaxModal, { toggle: toggleSyntaxModal }] = useToggle(false);
  return (
    <Flex direction="row">
      {types.includes("view") && (
        <Tooltip title="View">
          <Button
            {...buttonProps}
            icon={<FileCodeIcon />}
            onClick={toggleSyntaxModal}
          >
            {viewText}
          </Button>
          <SyntaxHighlighterModal
            title={modalTitle || filename}
            width={800}
            open={visibleSyntaxModal}
            onCancel={toggleSyntaxModal}
            onOk={toggleSyntaxModal}
            code={code}
            language={language}
          />
        </Tooltip>
      )}
      {types.includes("download") && (
        <>
          <WhiteSpace direction="row" size="xs" />
          <Tooltip title="Download">
            <Button
              {...buttonProps}
              icon={<LucideDownload />}
              onClick={() => {
                downloadBlob(
                  new Blob([code], { type: "text/plain" }),
                  filename
                );
              }}
            >
              {downloadText}
            </Button>
          </Tooltip>
        </>
      )}
      {types.includes("copy") && (
        <>
          <WhiteSpace direction="row" size="xs" />
          <Tooltip title="Copy to clipboard">
            <CopyToClipboard
              text={code}
              onCopy={() => {
                message.success("Copied to clipboard");
              }}
            >
              <Button {...buttonProps} icon={<CopyOutlined />}>
                {copyText}
              </Button>
            </CopyToClipboard>
          </Tooltip>
        </>
      )}
    </Flex>
  );
};

export default CodeButtons;
