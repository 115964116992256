import { CheckOutlined } from "@ant-design/icons";
import Button, { ButtonProps } from "antd/es/button";
import Card from "antd/es/card";
import Divider from "antd/es/divider";
import Form from "antd/es/form";
import Modal from "antd/es/modal";
import Space from "antd/es/space";
import theme from "antd/lib/theme";
import graphql from "babel-plugin-relay/macro";
import { default as YAML } from "js-yaml";
import { LucideRedo } from "lucide-react";
import React, { useState } from "react";
import { useFragment, useMutation } from "react-relay";
import { useNavigate } from "react-router-dom";

import { generateRandomString } from "../helpers";
import { useMemoWhenToggledOn } from "../hooks";
import EnvironmentVariableList, {
  IEnvironmentVariable,
  KeyRules,
  getEnvironmentVariableArray,
  getEnvironmentVariableMap,
} from "./EnvironmentVariableList";
import PipelineJobNameFormItem from "./PipelineJobNameFormItem";
import { PipelineYAML } from "./PipelineYamlEditor";
import { PipelineJobCloneButtonMutation } from "./__generated__/PipelineJobCloneButtonMutation.graphql";
import { PipelineJobCloneButton_pipelineJob$key } from "./__generated__/PipelineJobCloneButton_pipelineJob.graphql";

interface Props extends ButtonProps {
  pipelineJobFragment: PipelineJobCloneButton_pipelineJob$key | null;
}

const PipelineJobCloneButton: React.FC<Props> = ({
  pipelineJobFragment,
  ...props
}) => {
  const pipelineJob = useFragment(
    graphql`
      fragment PipelineJobCloneButton_pipelineJob on PipelineJob {
        id
        name
        yaml
        oneshotEnvs
      }
    `,
    pipelineJobFragment
  );
  const [commitClone, isInFlightCommitClone] =
    useMutation<PipelineJobCloneButtonMutation>(graphql`
      mutation PipelineJobCloneButtonMutation($input: ClonePipelineJobInput!) {
        clonePipelineJob(input: $input) {
          pipelineJob {
            __typename
            ... on PipelineJob {
              id
            }
            ... on UnauthenticatedError {
              message
            }
          }
        }
      }
    `);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { token } = theme.useToken();

  const pipelineJobYaml = YAML.load(pipelineJob?.yaml ?? "") as PipelineYAML;

  const defaultPipelineJobName = useMemoWhenToggledOn(() => {
    const postfix = generateRandomString(4);
    return `${pipelineJob?.name}-${postfix}`;
  }, !!modalOpen);

  const omitHPCOptimizationOptions = (
    params: IEnvironmentVariable[]
  ): IEnvironmentVariable[] =>
    params.filter(
      (param) =>
        !["OMP_NUM_THREADS", "OPENBLAS_NUM_THREADS"].includes(param.key)
    );

  const initialEnvs: IEnvironmentVariable[] = getEnvironmentVariableArray(
    pipelineJobYaml.environment?.envs
  );
  form.setFieldValue(["pipeline", "envs"], initialEnvs);

  const initialOneshotEnvs = getEnvironmentVariableArray(
    JSON.parse(pipelineJob?.oneshotEnvs ?? "{}")
  );
  form.setFieldValue(["envs"], initialOneshotEnvs);

  return (
    <>
      <Button
        type="text"
        icon={<LucideRedo />}
        style={{ color: "black" }}
        size="small"
        onClick={() => {
          setModalOpen(true);
        }}
      >
        Clone
      </Button>
      <Modal
        open={modalOpen}
        onCancel={() => setModalOpen(false)}
        closable={false}
        title={`Clone pipeline job: ${pipelineJob?.name}`}
        okText={"Clone"}
        width={"fit-content"}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              const fields: {
                name: string;
                envs: IEnvironmentVariable[];
              } = values;
              const envs = getEnvironmentVariableMap(fields.envs);
              if (pipelineJob === null) {
                return;
              }
              commitClone({
                variables: {
                  input: {
                    id: pipelineJob.id,
                    name: fields.name ?? defaultPipelineJobName,
                    envs: JSON.stringify(envs),
                  },
                },
                onCompleted({ clonePipelineJob }, errors) {
                  if (
                    clonePipelineJob?.pipelineJob?.__typename === "PipelineJob"
                  ) {
                    const pipelineJobId = clonePipelineJob.pipelineJob.id;
                    Modal.confirm({
                      icon: <CheckOutlined />,
                      content:
                        "The pipeline job has been successfully cloned. Would you like to view the job details now?",
                      title: "Pipeline Job Cloned",
                      okText: "View Job",
                      cancelText: "Close",
                      okButtonProps: {
                        style: {
                          backgroundColor: token.colorPrimary,
                        },
                      },
                      onOk() {
                        navigate(`/pipeline-jobs/${pipelineJobId}`);
                      },
                    });
                    setModalOpen(false);
                  }
                },
              });
            })
            .catch((e) => {});
        }}
      >
        <Form form={form} preserve={true} disabled={isInFlightCommitClone}>
          <PipelineJobNameFormItem
            name={"name"}
            inputProps={{
              placeholder: defaultPipelineJobName,
              disabled: isInFlightCommitClone,
            }}
          />
          <Space split={<Divider type="vertical" />} align={"start"}>
            <Card title={"Pipeline-level Environment Variables"}>
              <Form.Item style={{ marginBottom: 0 }}>
                <EnvironmentVariableList name={["pipeline", "envs"]} disabled />
              </Form.Item>
            </Card>
            <Card title={"Task-level Environment Variables"}>
              <Form.Item style={{ marginBottom: 0 }}>
                {pipelineJobYaml.tasks?.map((task, index) => {
                  const initialEnvs: IEnvironmentVariable[] =
                    getEnvironmentVariableArray(task.environment?.envs);
                  form.setFieldValue(
                    ["tasks", task.name, "envs"],
                    omitHPCOptimizationOptions(initialEnvs)
                  );
                  return (
                    <div key={index}>
                      <Divider
                        plain
                        orientation={"center"}
                        variant={"dashed"}
                        style={{ marginTop: 0 }}
                      >
                        {task.name}
                      </Divider>
                      <Form.Item>
                        <EnvironmentVariableList
                          name={["tasks", `${task.name}`, "envs"]}
                          keyRules={[
                            KeyRules.BLOCK_BACKENDAI_PREFIX,
                            KeyRules.BLOCK_HPC_OPTIONS,
                          ]}
                          disabled
                        />
                      </Form.Item>
                    </div>
                  );
                })}
              </Form.Item>
            </Card>
            <Card title={"One-shot Environment Variables"}>
              <Form.Item style={{ marginBottom: 0 }}>
                <EnvironmentVariableList name={["envs"]} />
              </Form.Item>
            </Card>
          </Space>
        </Form>
      </Modal>
    </>
  );
};

export default PipelineJobCloneButton;
