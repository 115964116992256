import Typography from "antd/es/typography";
import theme from "antd/lib/theme";
import { CpuIcon, MemoryStickIcon } from "lucide-react";
import React from "react";

import { ResourceSlot } from "../hooks/backendai";
import Flex from "./Flex";

interface Props {
  resourceSlot: ResourceSlot | "cpu" | "mem";
  value?: number;
}

const TaskInstanceResourceSlot: React.FC<Props> = ({ resourceSlot, value }) => {
  const { token } = theme.useToken();
  const { icon, displayUnit } = getResourceSlotMetadata(resourceSlot);
  return (
    <Flex
      gap={"xxs"}
      style={{
        fontFamily: "monospace",
      }}
    >
      {resourceSlot === "cpu" ? (
        <>
          <CpuIcon color={token.colorPrimary} />
          {value}{" "}
          <Typography.Text style={{ color: token.colorTextDescription }}>
            Core{value && value > 1 ? "s" : ""}
          </Typography.Text>
        </>
      ) : resourceSlot === "mem" ? (
        <>
          <MemoryStickIcon color={token.colorPrimary} />
          {value?.toFixed(2)}
          <Typography.Text style={{ color: token.colorTextDescription }}>
            GiB
          </Typography.Text>
        </>
      ) : (
        <>
          <img
            style={{
              width: "1em",
              height: "1em",
            }} // css !important
            src={`/static/icons/${icon}`}
          />
          {resourceSlot.endsWith(".shares") ? value?.toFixed(2) : value}
          <Typography.Text
            style={{
              color: token.colorTextDescription,
            }}
          >
            {displayUnit ??
              `Unit${parseInt(value?.toString() ?? "0") > 1 ? "s" : ""}`}
          </Typography.Text>
        </>
      )}
    </Flex>
  );
};

export function getResourceSlotMetadata(
  resourceSlot: ResourceSlot | "cpu" | "mem"
): {
  icon?: string;
  displayUnit: string;
} {
  switch (true) {
    case resourceSlot === "cuda.shares":
      return { icon: "nvidia.svg", displayUnit: "fGPU" };
    case resourceSlot.startsWith("cuda."): // e.g., cuda.device, cuda.device:1g.5gb-mig
      return { icon: "nvidia.svg", displayUnit: "GPU" };
    case resourceSlot.startsWith("rocm."):
      return { icon: "rocm.svg", displayUnit: "GPU" };
    case resourceSlot.startsWith("tpu."):
      return { icon: "tpu.svg", displayUnit: "TPU" };
    case resourceSlot.startsWith("gaudi2."):
      return { icon: "gaudi.svg", displayUnit: "Gaudi 2" };
    case resourceSlot.startsWith("ipu."):
      return { icon: "ipu.svg", displayUnit: "IPU" };
    case resourceSlot.startsWith("atom."):
      return { icon: "rebel.svg", displayUnit: "ATOM" };
    case resourceSlot.startsWith("atom-plus."):
      return { icon: "rebel.svg", displayUnit: "ATOM+" };
    case resourceSlot.startsWith("warboy."):
      return { icon: "furiosa.svg", displayUnit: "Warboy" };
    case resourceSlot.startsWith("rngd."):
      return { icon: "furiosa.svg", displayUnit: "RNGD" };
    case resourceSlot.startsWith("sapeon-"): // e.g., sapeon-x220.device
      return { icon: "sapeon.png", displayUnit: "X220" };
    default:
      return {
        icon: "npu.svg",
        displayUnit: resourceSlot.split(".")[0].toUpperCase(),
      };
  }
}

export default TaskInstanceResourceSlot;
