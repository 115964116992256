import Divider from "antd/es/divider";
import Form from "antd/es/form";
import Input from "antd/es/input";
import Switch from "antd/es/switch";
import { useAtom } from "jotai";

import { imagePresetOptionAtom } from "../hooks/atoms";
import { useImageGroupsState } from "../hooks/backendai";
import Flex from "./Flex";

const TaskEnvironmentNameFormInput = () => {
  const form = Form.useFormInstance();
  const [, { getImageInfoByKey }] = useImageGroupsState();
  const [isUsingImagePreset, setIsUsingImagePreset] = useAtom(
    imagePresetOptionAtom
  );
  return (
    <Form.Item
      name={"image"}
      label={
        <Flex
          direction="row"
          align="center"
          justify="around"
          style={{ width: "100%" }}
        >
          Environment
          <Divider type={"vertical"} />
          <Flex direction="row" align="center" justify="end" gap={"xs"}>
            <Switch
              size={"small"}
              onChange={(checked) => {
                setIsUsingImagePreset(checked);
                if (checked) {
                  const image = form.getFieldValue("image");
                  const imageInfo = getImageInfoByKey(image ?? "");
                  form.setFieldValue("environment", imageInfo.subGroupName);
                }
              }}
              value={isUsingImagePreset}
            />
            Use preset
          </Flex>
        </Flex>
      }
      rules={[{ required: true }]}
      labelCol={{ style: { width: "100%" } }}
    >
      <Input
        type="text"
        placeholder="cr.backend.ai/multiarch/python:3.10-ubuntu20.04"
      />
    </Form.Item>
  );
};

export default TaskEnvironmentNameFormInput;
