import { ReloadOutlined } from "@ant-design/icons";
import { LazyLog, ScrollFollow } from "@melloware/react-logviewer";
import { useQuery } from "@tanstack/react-query";
import { AnsiUp } from "ansi-up";
import Button from "antd/es/button";
import Divider from "antd/es/divider";
import Modal, { ModalProps } from "antd/es/modal";
import Select from "antd/es/select";
import Tooltip from "antd/es/tooltip";
import theme from "antd/lib/theme";
import { useAtomValue } from "jotai";
import _ from "lodash";
import { LucideDownload } from "lucide-react";
import React, { useCallback, useEffect, useState } from "react";

import { downloadBlob, from_global_id } from "../helpers";
import { projectListAtom } from "../hooks/atoms";
import { baiFetch } from "../hooks/auth";
import { minifyGraphQLQuery } from "../hooks/backendai";
import { useMemoWithPrevious } from "../hooks/memo";
import Flex from "./Flex";

interface IKernelNode {
  id: string;
  container_id?: string;
  cluster_role: string;
  cluster_idx: number;
}

interface Props extends ModalProps {
  onRequestClose?: () => void;
  taskInstanceGlobalId?: string;
  sessionId?: string;
  project?: string | null;
}

const ContainerLogModal: React.FC<Props> = ({
  onRequestClose,
  taskInstanceGlobalId,
  sessionId,
  project,
  ...modalProps
}) => {
  const { token } = theme.useToken();
  const projects = useAtomValue(projectListAtom);
  const projectId = projects.find((p) => p.name === project)?.id;

  const taskInstanceId: string | undefined = from_global_id(
    taskInstanceGlobalId ?? ""
  )[1];
  const { isLoading, data, refetch, dataUpdatedAt } = useQuery({
    queryKey: ["ContainerLogModal/logs", taskInstanceGlobalId],
    queryFn: async () => {
      const params: { kernel_id?: string } = {};
      if (selectedKernelId !== undefined) {
        params["kernel_id"] = from_global_id(selectedKernelId)[1];
      }
      const queryParams = Object.entries(params)
        .map((p) => p.join("="))
        .join("&");
      return baiFetch(
        `/pipeline/task-instances/${taskInstanceId}/logs?${queryParams}`,
        {
          method: "GET",
        }
      ).then((res) => res.json());
    },
    enabled: taskInstanceId !== undefined,
  });

  const response: {
    result?: {
      // on success
      logs: string;
    };
    message?: string; // on error
  } = data;

  const [kernelNodes, setKernelNodes] = useState<IKernelNode[]>();
  const [selectedKernelId, setSelectedKernelId] = useState<string>();

  const onSessionIdChange = useCallback(
    async (sessionId: string, projectId: string) => {
      const session = await baiFetch("/func/admin/gql", {
        method: "POST",
        body: minifyGraphQLQuery(
          JSON.stringify({
            query: `
            query ComputeSessionNodeQuery(
              $id: GlobalIDField!
              $project_id: UUID!
            ) {
              compute_session_node(id: $id, project_id: $project_id) {
                id
                kernel_nodes {
                  edges {
                    node {
                      id
                      container_id
                      cluster_role
                      cluster_idx
                    }
                  }
                }
              }
            }
          `,
            variables: {
              id: sessionId,
              project_id: projectId,
            },
          })
        ),
      }).then((res) => res.json());
      const newKernelNodes =
        session?.data?.compute_session_node?.kernel_nodes?.edges?.map(
          (e: { node: IKernelNode }) => e?.node
        ) ?? [];
      setKernelNodes(newKernelNodes);
      setSelectedKernelId(
        newKernelNodes?.find((k: IKernelNode) => k.cluster_role === "main")
          ?.id ?? selectedKernelId
      );
    },
    [sessionId, projectId]
  );

  useEffect(() => {
    if (sessionId !== undefined && projectId !== undefined) {
      onSessionIdChange(sessionId, projectId);
    }
  }, [sessionId, projectId]);

  useEffect(() => {
    refetch();
  }, [selectedKernelId]);

  const [lastLineNumber, { resetPrevious: resetLastLineNumber }] =
    useMemoWithPrevious(
      () => response?.result?.logs.split("\n").length ?? 0,
      [response?.result?.logs]
    );

  return (
    <Modal
      width={1000}
      styles={{
        header: {
          width: "100%",
        },
        body: {
          height: "calc(80vh)",
        },
      }}
      {...modalProps}
      onCancel={onRequestClose}
      footer={null}
    >
      <Flex
        direction="column"
        align="start"
        style={{ height: "100%" }}
        gap={"sm"}
      >
        <Flex gap="sm" wrap="wrap">
          Kernel Role
          <Select
            value={selectedKernelId}
            onChange={(value) => {
              setSelectedKernelId(value);
              resetLastLineNumber();
            }}
            options={kernelNodes
              ?.map((kernel) => ({
                label: `${kernel.cluster_role}${kernel.cluster_role === "sub" ? kernel.cluster_idx : ""}`,
                value: kernel.id,
              }))
              ?.sort((a, b) => a.label.localeCompare(b.label))}
          />
          <Divider type="vertical" />
          <Tooltip title={"Download"}>
            <Button
              size="middle"
              icon={<LucideDownload />}
              disabled={isLoading || !response.result?.logs}
              onClick={() => {
                downloadBlob(
                  new Blob(
                    [new AnsiUp().ansi_to_text(response.result?.logs ?? "")],
                    { type: "text/plain" }
                  ),
                  `session-logs-${selectedKernelId}-${new Date().toISOString()}.log`
                );
              }}
            />
          </Tooltip>
          <Tooltip title={"Refresh"}>
            <Button
              size="middle"
              loading={isLoading}
              icon={<ReloadOutlined />}
              onClick={() => refetch()}
            />
          </Tooltip>
        </Flex>

        <div
          style={{
            height: "calc(100% - 50px)",
            alignSelf: "stretch",
            border: `1px solid ${token.colorBorder}`,
            overflow: "hidden",
          }}
        >
          <ScrollFollow
            key={dataUpdatedAt}
            startFollowing={true}
            render={({ follow, onScroll }) => (
              <LazyLog
                enableLinks
                caseInsensitive
                enableSearch
                enableSearchNavigation
                selectableLines
                text={response?.result?.logs || "No logs available."}
                enableLineNumbers
                enableMultilineHighlight
                highlight={lastLineNumber.previous}
                // extraLines={1}
                follow={follow}
                onScroll={onScroll}
              />
            )}
          />
        </div>
      </Flex>
    </Modal>
  );
};

export default ContainerLogModal;
