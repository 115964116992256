import { useToggle } from "ahooks";
import Card from "antd/es/card";
import Checkbox from "antd/es/checkbox";
import Form, { FormInstance, FormProps } from "antd/es/form";
import { Col, Row } from "antd/es/grid";
import Input from "antd/es/input";
import InputNumber from "antd/es/input-number";
import Select from "antd/es/select";
import Switch from "antd/es/switch";
import Typography from "antd/es/typography";
import TextArea from "antd/lib/input/TextArea";
import theme from "antd/lib/theme";
import graphql from "babel-plugin-relay/macro";
import { default as YAML } from "js-yaml";
import _ from "lodash";
import React from "react";
import { useFragment } from "react-relay";

import { numberMinRule } from "../helpers";
import {
  DEAD_VFOLDER_STATUSES,
  ResourceSlot,
  useAPIVersionCompatibility,
} from "../hooks/backendai";
import EnvironmentVariableList, {
  IEnvironmentVariable,
  KeyRules,
  getEnvironmentVariableArray,
} from "./EnvironmentVariableList";
import Flex from "./Flex";
import { PipelineYAML } from "./PipelineYamlEditor";
import ResourcesInputItems, { ResourcesFormInput } from "./ResourcesInputItems";
import VirtualFolderMountFormList from "./VirtualFolderMountFormList";
import { PipelineTaskForm_pipeline$key } from "./__generated__/PipelineTaskForm_pipeline.graphql";

const VirtualFolderSelectorModal = React.lazy(
  () => import("./VirtualFolderSelectorModal")
);

const { Text } = Typography;

export type ClusterMode = "single-node" | "multi-node";

export interface PipelineTaskFormInput extends ResourcesFormInput {
  id: string;
  name?: string;
  description?: string;
  skip?: boolean;
  type?: string;
  clusterMode: ClusterMode;
  clusterSize: number;
  envs?: IEnvironmentVariable[];
  command?: string;
  vFolders?: {
    id?: string;
    name: string;
    alias?: string;
  }[];
  scalingGroup?: string;
  setDefault?: boolean;
  hpcOptimization: {
    autoEnabled: boolean;
    OMP_NUM_THREADS: string;
    OPENBLAS_NUM_THREADS: string;
  };
}

interface PipelineTaskFormProps extends FormProps {
  form: FormInstance<PipelineTaskFormInput>;
  pipelineFrgmt: PipelineTaskForm_pipeline$key | null;
  invalidNames?: string[];
  resourceSlots?: ResourceSlot[];
}

const PipelineTaskForm: React.FC<PipelineTaskFormProps> = ({
  form,
  pipelineFrgmt,
  invalidNames,
  resourceSlots = [],
  ...props
}) => {
  const pipeline = useFragment(
    graphql`
      fragment PipelineTaskForm_pipeline on Pipeline {
        yaml
        storage
      }
    `,
    pipelineFrgmt
  );

  const { token } = theme.useToken();
  const supportsMultiContainer = useAPIVersionCompatibility("v6.20200815"); // https://github.com/lablup/backend.ai-webui/blob/16f14cc7d097dd5eba41adbcc04347b95a5dafe2/src/lib/backend.ai-client-esm.ts#L563-L564
  const [visibleFolderModal, { toggle: toggleFolderModal }] = useToggle(false);

  const pipelineYaml = YAML.load(pipeline?.yaml ?? "") as PipelineYAML;

  // Remove one occurrence of the task name from the list using
  const pipelineDedicatedFolderName = pipeline?.storage
    ? JSON.parse(pipeline.storage).name
    : undefined;

  const maxTaskNameLength = 20;

  return (
    <Form form={form} layout="vertical" scrollToFirstError={true} {...props}>
      <Form.Item hidden name="id">
        <Input />
      </Form.Item>
      <Form.Item name={"skip"} valuePropName="checked" hidden>
        <Switch />
      </Form.Item>
      <Form.Item
        name="name"
        label="Task Name"
        rules={[
          {
            required: true,
            message: "Please input the name of the task!",
          },
          {
            max: maxTaskNameLength,
            message: "Task name may not be longer than 20 characters.",
          },
          {
            pattern: /^[a-zA-Z0-9_-]+$/,
            message:
              "Task name must be composed of letters, numbers, underscores, and hyphens",
          },
          {
            validator: (__, value) =>
              _.includes(invalidNames, value)
                ? Promise.reject(
                    new Error("You can not use the same name for two tasks")
                  )
                : Promise.resolve(),
          },
        ]}
      >
        <Input count={{ show: true, max: maxTaskNameLength }} />
      </Form.Item>
      <Form.Item name="description" label="Description">
        <TextArea disabled={props.disabled} />
      </Form.Item>
      <Form.Item
        name="type"
        label="Task Type"
        initialValue={"custom"}
        tooltip="More task types are under development."
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Select disabled>
          <Select.Option key="custom">Custom Task</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item
        label="Environment Variables"
        tooltip="These environment variables will be assigned to the task. Each variable can override a pipeline-level variable with the same name."
      >
        <EnvironmentVariableList
          name={"envs"}
          keyRules={[
            KeyRules.BLOCK_BACKENDAI_PREFIX,
            KeyRules.BLOCK_HPC_OPTIONS,
          ]}
          inheritedEnvironmentVariables={getEnvironmentVariableArray(
            pipelineYaml?.environment?.envs
          )}
        />
      </Form.Item>
      <Form.Item name="command" label="Command" rules={[{ required: true }]}>
        <TextArea style={{ fontFamily: "Monaco" }} disabled={props.disabled} />
      </Form.Item>
      <Form.Item
        label={<Text strong>Session Configurations</Text>}
        style={{
          backgroundColor: "#fafafa",
          padding: "16px 16px 0 16px",
          border: "1px solid",
          borderColor: token.colorBorder,
          borderRadius: "2px",
        }}
      >
        <ResourcesInputItems
          pipelineFrgmt={pipelineFrgmt}
          resourceSlots={resourceSlots}
        />
        {supportsMultiContainer ? (
          <Form.Item
            label="Cluster Mode"
            tooltip="When running a session, the managed node and worker node(s) can be placed on a single physical node/virtual machine or split across multiple physical nodes/virtual machines."
            rules={[{ required: true }]}
            style={{ marginBottom: 0 }}
          >
            <Flex direction="row" align="center" gap={"xs"}>
              <Form.Item name="clusterMode" rules={[{ required: true }]}>
                <Select style={{ width: "256px" }}>
                  <Select.Option value="single-node">single-node</Select.Option>
                  <Select.Option value="multi-node">multi-node</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item
                style={{ marginBottom: 0 }}
                shouldUpdate={(prevValues, nextValues) => {
                  if (prevValues.clusterSize !== nextValues.clusterSize) {
                    return true;
                  }
                  if (prevValues.clusterMode === nextValues.clusterMode) {
                    return false;
                  }
                  if (nextValues.clusterMode === "single-node") {
                    nextValues.clusterSize = 1;
                  }
                  return true;
                }}
              >
                {({ getFieldValue }) => {
                  return (
                    <Form.Item
                      name="clusterSize"
                      rules={[
                        {
                          required: true,
                        },
                        numberMinRule(
                          1,
                          "Cluster Size must be greater than or equal to 1"
                        ),
                      ]}
                    >
                      <InputNumber
                        style={{ width: "100%" }}
                        suffix={
                          (getFieldValue("clusterMode") === "multi-node"
                            ? "Node"
                            : "Container") +
                          (getFieldValue("clusterSize") > 1 ? "s" : "")
                        }
                        disabled={props.disabled}
                      />
                    </Form.Item>
                  );
                }}
              </Form.Item>
            </Flex>
          </Form.Item>
        ) : null}
        <VirtualFolderMountFormList
          name={"vFolders"}
          pipelineFrgmt={pipelineFrgmt}
          onClick={() => {
            toggleFolderModal();
          }}
        />
        <Form.Item name="setDefault" valuePropName="checked">
          <Checkbox>
            Set these resource selections to the default values for this
            pipeline.
          </Checkbox>
        </Form.Item>
      </Form.Item>
      <Card
        title={"High-Performance Computing Optimizations"}
        style={{ display: "block" }}
      >
        <Form.Item noStyle>
          <Flex direction="row" gap={"sm"}>
            <Typography.Text>Use automatic OpenMP optimization</Typography.Text>
            <Form.Item
              label={"High-Performance Computing Optimizations"}
              name={["hpcOptimization", "autoEnabled"]}
              valuePropName={"checked"}
              required
              noStyle
            >
              <Switch
                checkedChildren={"ON"}
                unCheckedChildren={"OFF"}
                onChange={(checked) => {
                  if (checked) {
                    form.setFieldsValue({
                      hpcOptimization: {
                        OMP_NUM_THREADS: "1",
                        OPENBLAS_NUM_THREADS: "1",
                      },
                    });
                  }
                }}
              />
            </Form.Item>
          </Flex>
        </Form.Item>
        <Form.Item
          noStyle
          shouldUpdate={(prevValues, nextValues) => {
            return (
              prevValues.hpcOptimization?.autoEnabled !==
              nextValues.hpcOptimization?.autoEnabled
            );
          }}
        >
          {() => {
            const enabled = form.getFieldValue([
              "hpcOptimization",
              "autoEnabled",
            ]);
            return (
              <Row
                gutter={token.marginMD}
                style={{
                  display: enabled ? "none" : undefined,
                  marginTop: token.marginMD,
                }}
              >
                <Col xs={24} sm={12}>
                  <Form.Item
                    style={{ flex: 1 }}
                    label={"OpenMP threads"}
                    name={["hpcOptimization", "OMP_NUM_THREADS"]}
                    tooltip={
                      <>
                        OpenMP/OpenBLAS Optimization
                        <p>
                          This value sets the number of threads to use for
                          parallel regions by setting the initial value of the
                          nthreads-var internal control variable.
                        </p>
                        <p>
                          Backend.AI sets this value equal to the number of CPU
                          cores, which has the effect of accelerating typical
                          high-performance computing workloads. However, for
                          some multi-threaded workloads, multiple processes
                          using OpenMP are used at the same time, resulting in
                          an abnormally large number of threads and significant
                          performance degradation. In this case, please adjust
                          this value to 1 or 2.
                        </p>
                      </>
                    }
                    required
                  >
                    <InputNumber
                      min={0}
                      max={1000}
                      step={1}
                      stringMode
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item
                    style={{ flex: 1 }}
                    label={"OpenBLAS threads"}
                    name={["hpcOptimization", "OPENBLAS_NUM_THREADS"]}
                    tooltip={
                      <>
                        OpenMP/OpenBLAS Optimization
                        <p>
                          This value sets the number of threads to use for
                          parallel regions by setting the initial value of the
                          nthreads-var internal control variable.
                        </p>
                        <p>
                          Backend.AI sets this value equal to the number of CPU
                          cores, which has the effect of accelerating typical
                          high-performance computing workloads. However, for
                          some multi-threaded workloads, multiple processes
                          using OpenMP are used at the same time, resulting in
                          an abnormally large number of threads and significant
                          performance degradation. In this case, please adjust
                          this value to 1 or 2.
                        </p>
                      </>
                    }
                    required
                  >
                    <InputNumber
                      min={0}
                      max={1000}
                      step={1}
                      stringMode
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            );
          }}
        </Form.Item>
      </Card>
      <VirtualFolderSelectorModal
        open={visibleFolderModal}
        defaultSelectedKeys={_.map(form.getFieldValue("vFolders"), "id")}
        disabledKeys={[pipelineDedicatedFolderName]}
        filter={(folder) =>
          !folder.name.startsWith(".") &&
          !DEAD_VFOLDER_STATUSES.includes(folder.status)
        }
        onRequestClose={(nextFolders) => {
          toggleFolderModal();
          if (nextFolders === undefined) {
            return;
          }
          const prevFolders = form.getFieldValue("vFolders");
          const existedFolders = _.intersection(nextFolders, prevFolders);
          const addedFolders = _.difference(nextFolders, prevFolders);

          form.setFieldValue(
            "vFolders",
            _.map([...existedFolders, ...addedFolders], (folder, index) => {
              return {
                name: folder.name,
                id: folder.id,
                alias: form.getFieldValue(["vFolders", index, "alias"]),
              };
            })
          );
        }}
      />
    </Form>
  );
};

export default React.memo(PipelineTaskForm);
