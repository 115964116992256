import { HomeOutlined } from "@ant-design/icons";
import Breadcrumb, { BreadcrumbItemType } from "antd/es/breadcrumb/Breadcrumb";
import Divider from "antd/es/divider";
import Radio from "antd/es/radio";
import Space from "antd/es/space";
import Tag from "antd/es/tag";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { StringParam, useQueryParam, withDefault } from "use-query-params";

import AutoRefreshSwitch from "../components/AutoRefreshSwitch";
import Flex from "../components/Flex";
import { from_global_id } from "../helpers";

const PipelineJobTable = React.lazy(
  () => import("../components/PipelineJobTable")
);

const PipelineJobList = () => {
  const [pipelineId, setPipelineId] = useQueryParam("pipelineId", StringParam);
  const [tab, setTab] = useQueryParam(
    "tab",
    withDefault(StringParam, "active")
  );
  const [refreshKey, setRefreshKey] = useState<number>(0);

  const items: BreadcrumbItemType[] = [
    {
      title: (
        <Link to={"/"}>
          <HomeOutlined />
        </Link>
      ),
    },
    {
      title: "Pipeline Jobs",
    },
  ];

  const options = [
    { label: "Active", value: "active" },
    { label: "Archived", value: "archived" },
  ];

  return (
    <div style={{ padding: 16 }}>
      <Space direction="vertical" style={{ width: "100%" }}>
        <Flex direction="row" justify="between">
          <Breadcrumb items={items} />
        </Flex>
        {pipelineId ? (
          <Space direction="horizontal">
            filter:
            <Tag
              closable
              onClose={() => {
                setPipelineId(null);
              }}
            >
              pipeline <Divider type="vertical" style={{ margin: "0 4px" }} />
              {from_global_id(pipelineId)[1]}
            </Tag>
          </Space>
        ) : null}
        <Flex direction="row" align="center" justify="between">
          <Radio.Group
            block
            options={options}
            defaultValue={tab}
            optionType="button"
            buttonStyle="solid"
            onChange={(e) => {
              setTab(e.target.value);
            }}
          />
          <AutoRefreshSwitch
            onRefresh={() => {
              setRefreshKey((v) => v + 1);
            }}
            interval={5000}
          >
            Auto-refresh every 5 seconds
          </AutoRefreshSwitch>
        </Flex>
        <PipelineJobTable
          pipelineId={pipelineId}
          filter={{ recordStatus: { exact: tab.toUpperCase() } }}
          tab={tab}
          refreshKey={refreshKey}
        />
      </Space>
    </div>
  );
};

export default PipelineJobList;
