import Form, { FormItemProps } from "antd/es/form";
import Input, { InputProps } from "antd/es/input";
import React from "react";

interface Props extends FormItemProps {
  inputProps?: InputProps;
}

const MAX_PIPELINE_JOB_NAME_LENGTH = 200;

const PipelineJobNameFormItem: React.FC<Props> = ({ inputProps, ...props }) => {
  return (
    <Form.Item
      name={"name"}
      label={"PipelineJob Name"}
      rules={[
        {
          required: false,
        },
        {
          max: MAX_PIPELINE_JOB_NAME_LENGTH,
          message: `The pipeline job name must be under ${MAX_PIPELINE_JOB_NAME_LENGTH} characters.`,
        },
        {
          pattern: /^[a-zA-Z0-9._-]+$/,
          message:
            "The pipeline job name can include letters, numbers, dots, underscores, and hyphens.",
        },
      ]}
      {...props}
    >
      <Input
        showCount
        maxLength={MAX_PIPELINE_JOB_NAME_LENGTH}
        {...inputProps}
        placeholder={inputProps?.placeholder?.slice(
          0,
          MAX_PIPELINE_JOB_NAME_LENGTH
        )}
      />
    </Form.Item>
  );
};

export default PipelineJobNameFormItem;
