import { FileOutlined, FolderOutlined } from "@ant-design/icons";
import { useDynamicList, useUpdateEffect } from "ahooks";
import Breadcrumb, { BreadcrumbItemType } from "antd/es/breadcrumb/Breadcrumb";
import Button, { ButtonProps } from "antd/es/button";
import Modal, { ModalProps } from "antd/es/modal";
import Table from "antd/es/table";
import Tooltip from "antd/es/tooltip";
import dayjs from "dayjs";
import { useAtomValue } from "jotai";
import _ from "lodash";
import React, { useState, useTransition } from "react";

import { fileListAtom } from "../../hooks/atoms";
import { VirtualFolder } from "../../hooks/backendai";
import Flex from "../Flex";
import WhiteSpace from "../WhiteSpace";
import FolderDownloadButton from "./FolderDownloadButton";

interface Props extends ModalProps {
  defaultSelectedKeys?: React.Key[];
  folderName?: string;
  initialPath?: string;
  onRequestClose: (selectedVFolders?: VirtualFolder[]) => void;
  filter?: (folder: VirtualFolder) => boolean;
}

const FolderExplorer: React.FC<Props> = ({
  onRequestClose,
  defaultSelectedKeys,
  folderName,
  initialPath = ".",
  filter,
  ...props
}) => {
  const { list: path, ...orgPathActions } = useDynamicList(
    initialPath.split("/")
  );

  const pathMutationInTransition: Pick<
    typeof orgPathActions,
    "resetList" | "push" | "pop"
  > = {
    resetList: (newList) => {
      startTransition(() => {
        orgPathActions.resetList(newList);
      });
    },
    push: (newItem) => {
      startTransition(() => {
        orgPathActions.push(newItem);
      });
    },
    pop: () => {
      startTransition(() => {
        orgPathActions.pop();
      });
    },
  };

  const [isPending, startTransition] = useTransition();
  const fileList = useAtomValue(
    fileListAtom({
      path: encodeURIComponent(path.join("/")),
      vFolderName: folderName || "",
    })
  );

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>(
    defaultSelectedKeys || []
  );

  useUpdateEffect(() => {
    setSelectedRowKeys(defaultSelectedKeys || []);
  }, [defaultSelectedKeys]);

  const items: BreadcrumbItemType[] = _.map(path, (p, i) => {
    return i === 0
      ? {
          title: <FolderOutlined style={{ cursor: "pointer" }} />,
          onClick(e: React.MouseEvent) {
            e.preventDefault();
            pathMutationInTransition.resetList(["."]);
          },
          href: "#",
        }
      : {
          title: p,
          onClick(e: React.MouseEvent) {
            e.preventDefault();
            pathMutationInTransition.resetList(path.slice(0, i + 1));
          },
          href: i !== path.length - 1 ? "#" : undefined,
        };
  });

  return (
    <Modal
      {...props}
      footer={null}
      width={"80%"}
      zIndex={1031}
      destroyOnClose
      onCancel={() => {
        onRequestClose();
      }}
      title={<span>{folderName}</span>}
    >
      {/* <span>{selectedRowKeys.length} folder selected</span> */}
      <Flex>
        <Breadcrumb separator=">" items={items} />
        {/* <WhiteSpace direction="row" />
        <Text
          copyable={{
            text: path.join("/"),
            tooltips: 'Copy "path" to clipboard',
          }}
        /> */}
      </Flex>
      <WhiteSpace direction="column" size="lg" />
      <Table
        // size="small"
        rowKey={(record) => record.name}
        // rowSelection={{
        //   selectedRowKeys,
        //   onChange: (selectedRowKeys) => {
        //     setSelectedRowKeys(selectedRowKeys);
        //   },
        // }}
        showSorterTooltip={false}
        pagination={false}
        columns={[
          {
            title: "Name",
            dataIndex: "name",
            align: "left",
            render: (text, record) => {
              return (
                <Flex direction="row">
                  {record.type == "DIRECTORY" ? (
                    <Button
                      type="link"
                      style={{ padding: 2 }}
                      icon={<FolderOutlined />}
                      onClick={(e) => {
                        e.preventDefault();
                        pathMutationInTransition.push(text);
                      }}
                    >
                      {text}
                    </Button>
                  ) : (
                    <>
                      <FileOutlined style={{ margin: 5 }} />
                      {text}
                    </>
                  )}
                </Flex>
              );
            },
            sorter: (a, b) => a.name.localeCompare(b.name),
          },
          {
            title: "Created",
            dataIndex: "created",
            render: (text) => dayjs(text).format("lll"),
            ellipsis: true,
            sorter: (a, b) => dayjs(a.created).diff(b.created),
          },
          {
            title: "Type",
            dataIndex: "type",
            render: (text) => (text === "DIRECTORY" ? "Folder" : "File"),
            sorter: (a, b) => a.type.localeCompare(b.type),
          },
          {
            title: "Modified",
            dataIndex: "modified",
            render: (text) => dayjs(text).format("lll"),
            ellipsis: true,
            sorter: (a, b) => dayjs(a.modified).diff(b.modified),
          },
          {
            title: "Size",
            dataIndex: "size",
            align: "right",
            sorter: (a, b) => dayjs(a.size).diff(b.size),
            // render: (text) => {
          },
          {
            title: "Actions",
            dataIndex: "actions",
            render: (text, record) => {
              return (
                <Tooltip title="Download">
                  {folderName ? (
                    <FolderDownloadButton
                      folderName={folderName}
                      path={path.join("/") + `/${record.name}`}
                      archive={record.type === "DIRECTORY"}
                    />
                  ) : null}
                </Tooltip>
              );
            },
          },
        ]}
        dataSource={fileList}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              event.stopPropagation();
              // console.log(selectedRowKeys);
              // selectedRowKeys.includes(record.name)
              //   ? setSelectedRowKeys(
              //       selectedRowKeys.filter((k) => k !== record.name)
              //     )
              //   : setSelectedRowKeys([record.name]);
              // // : setSelectedRowKeys([...selectedRowKeys, record.name]);
            },
            // onDoubleClick: (event) => {
            //   event.stopPropagation();
            //   if (record.type === "DIRECTORY") {
            //     pathMutationInTransition.push(record.name);
            //   }
            // },
          };
        }}
      />
    </Modal>
  );
};

interface FolderExplorerButtonProps extends ButtonProps {
  folderName?: string;
  initialPath?: string;
}

export const FolderExplorerButton: React.FC<FolderExplorerButtonProps> = ({
  folderName,
  initialPath,
  ...props
}) => {
  const [visible, setVisible] = useState(false);
  return (
    <>
      <Button
        type="link"
        style={{ padding: 0 }}
        icon={<FolderOutlined />}
        {...props}
        onClick={(e) => {
          setVisible(true);
        }}
      />
      <FolderExplorer
        folderName={folderName}
        initialPath={initialPath}
        open={visible}
        onRequestClose={() => {
          setVisible(false);
        }}
      />
    </>
  );
};

export default FolderExplorer;
