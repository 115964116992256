import { createStyles } from "antd-style";
import Cascader from "antd/es/cascader";
import Form from "antd/es/form";
import InputNumber from "antd/es/input-number";
import Select from "antd/es/select";
import Space from "antd/es/space";
import graphql from "babel-plugin-relay/macro";
import { useAtomValue } from "jotai";
import { default as YAML } from "js-yaml";
import _ from "lodash";
import React, { Suspense, useEffect } from "react";
import { useFragment } from "react-relay";

import { changeBinaryUnit, numberMinRule } from "../helpers";
import {
  Image,
  imagePresetOptionAtom,
  projectResourceGroupListAtom,
} from "../hooks/atoms";
import {
  ResourceSlot,
  useImageGroupsState,
  useResourceSlotsDetails,
} from "../hooks/backendai";
import Flex from "./Flex";
import {
  PipelineYAML,
  parseTaskLevelProjectResourceGroup,
} from "./PipelineYamlEditor";
import TaskEnvironmentFormInput from "./TaskEnvironmentFormInput";
import TaskEnvironmentNameFormInput from "./TaskEnvironmentNameFormInput";
import { PipelineTaskForm_pipeline$key } from "./__generated__/PipelineTaskForm_pipeline.graphql";
import { PipelineYamlEditorFragment$key } from "./__generated__/PipelineYamlEditorFragment.graphql";

const ResourceGroupCascader = React.lazy(
  () => import("./ResourceGroupCascader")
);

const useStyles = createStyles(({ token, css }) => {
  return {
    compactVerticalInput: css`
      & > :first-child .ant-input-group-addon {
        border-bottom-left-radius: 0px;
      }
      & > :first-child .ant-input-affix-wrapper {
        border-bottom-right-radius: 0px;
      }
      & > :not(:last-child) {
        margin-bottom: -1px;
      }

      & > :last-child .ant-input-group-addon {
        border-top-left-radius: 0px;
      }
      & > :last-child .ant-input-affix-wrapper {
        border-top-right-radius: 0px;
      }

      & > :not(:first-child):not(:last-child) .ant-input-group-addon,
      & > :not(:first-child):not(:last-child) .ant-input-affix-wrapper {
        border-radius: 0px;
      }
    `,
  };
});

export interface ResourcesFormInput {
  // scalingGroup: string;
  projectResourceGroup?: (string | undefined)[];
  environment?: string;
  image?: string;
  slots: {
    cpu?: number;
    memory?: number;
    [key: ResourceSlot]: number;
  };
  sharedMemory?: number;
  acceleratorType?: string; // filtered
}

export const getSystemRequirements = (
  image?: Image,
  resourceSlots: ResourceSlot[] = []
): {
  cpu: string;
  mem: string;
  [key: string]: string;
} => {
  const cpu =
    _.find(image?.labels, (l) => l.key === "ai.backend.resource.min.cpu")
      ?.value ?? "1";
  const mem =
    _.find(image?.labels, (l) => l.key === "ai.backend.resource.min.mem")
      ?.value ?? "0";

  const minValue = _(resourceSlots).reduce((previousValue, currentValue) => {
    const resourceLabel = _.find(
      image?.labels,
      (label) => label.key === `ai.backend.resource.min.${currentValue}`
    );
    return {
      ...previousValue,
      [currentValue]: resourceLabel ? resourceLabel.value : "0",
    };
  }, {});

  return {
    cpu,
    mem,
    ...minValue,
  };
};

const RESOURCE_SLOT_LABEL_DEFS: { [key: string]: string } = {
  rocm: "ROCm",
};

interface Props {
  resourceSlots?: ResourceSlot[];
  pipelineFrgmt:
    | PipelineTaskForm_pipeline$key
    | PipelineYamlEditorFragment$key
    | null;
}

const ResourcesInputItems: React.FC<Props> = ({
  resourceSlots = [],
  pipelineFrgmt,
}) => {
  const pipeline = useFragment(
    graphql`
      fragment ResourcesInputItems_pipeline on Pipeline {
        yaml
      }
    `,
    pipelineFrgmt
  );
  const [, { getImageInfoByKey }] = useImageGroupsState();
  const form = Form.useFormInstance();

  const image = Form.useWatch("image", form);

  const selectedImageInfo = image && getImageInfoByKey(image);
  const systemRequirements = getSystemRequirements(
    selectedImageInfo?.image,
    resourceSlots
  );

  const resourceGroupsPerProject = useAtomValue(projectResourceGroupListAtom);
  const project = _.find(
    resourceGroupsPerProject.projects,
    (project) => !_.isEmpty(project.resourceGroup)
  );
  const defaultProjectId = project?.resourceGroup[0] ?? "default";
  const resourceSlotsDetails = useResourceSlotsDetails(defaultProjectId);
  const { styles } = useStyles();

  const pipelineYaml = (YAML.load(pipeline?.yaml || "") as PipelineYAML) ?? {};
  const ownership = pipelineYaml?.ownership;
  const tasks = pipelineYaml.tasks;
  const currentTask = tasks?.find(
    (task) => task.name === form.getFieldValue("name")
  );

  const taskLevelProjectResourceGroup = parseTaskLevelProjectResourceGroup(
    pipelineYaml,
    currentTask
  );

  useEffect(() => {
    form.setFieldValue("projectResourceGroup", taskLevelProjectResourceGroup);
  }, []);

  const isUsingImagePreset = useAtomValue(imagePresetOptionAtom);

  return (
    <>
      <Form.Item
        style={{ marginBottom: 0 }}
        shouldUpdate={(previousValues, nextValues) => {
          return (
            previousValues.projectResourceGroup !==
            nextValues.projectResourceGroup
          );
        }}
      >
        {({ setFieldValue }) => {
          return (
            <Form.Item
              name="projectResourceGroup"
              label="Project / Resource Group"
              rules={[{ required: true }]}
            >
              <Suspense
                fallback={
                  <Cascader placeholder="Loading..." loading disabled />
                }
              >
                <ResourceGroupCascader
                  project={ownership?.project}
                  onChange={(value) => {
                    setFieldValue("projectResourceGroup", value);
                  }}
                  defaultValue={taskLevelProjectResourceGroup}
                />
              </Suspense>
            </Form.Item>
          );
        }}
      </Form.Item>
      {isUsingImagePreset ? (
        <TaskEnvironmentFormInput />
      ) : (
        <TaskEnvironmentNameFormInput />
      )}
      <Form.Item label="Resources">
        <Space.Compact
          className={styles.compactVerticalInput}
          direction="vertical"
          style={{ width: "100%" }}
        >
          <Form.Item
            shouldUpdate={(prevValues, nextValues) =>
              prevValues.slots?.cpu !== nextValues.slots?.cpu
            }
          >
            {({ getFieldValue }) => (
              <Form.Item
                name={["slots", "cpu"]}
                rules={[
                  { required: true },
                  numberMinRule(
                    parseInt(systemRequirements.cpu),
                    `CPU must be greater than or equal to ${parseInt(
                      systemRequirements.cpu
                    )}`
                  ),
                ]}
                noStyle
              >
                <InputNumber
                  style={{ width: "100%" }}
                  suffix={`Core${getFieldValue(["slots", "cpu"]) > 1 ? "s" : ""}`}
                  min={0}
                  addonBefore={<InstanceInputAddon>CPU</InstanceInputAddon>}
                />
              </Form.Item>
            )}
          </Form.Item>
          <Form.Item
            name={["slots", "memory"]}
            rules={[
              { required: true },
              numberMinRule(
                changeBinaryUnit(systemRequirements.mem, "g"),
                `Memory must be greater than or equal to ${changeBinaryUnit(
                  systemRequirements.mem,
                  "g"
                )}`
              ),
            ]}
            noStyle
          >
            <InputNumber<number>
              style={{ width: "100%" }}
              suffix={"GiB"}
              step={0.25}
              min={0}
              addonBefore={<InstanceInputAddon>Memory</InstanceInputAddon>}
              formatter={(value) => value?.toString() ?? "0"}
              parser={(value) => parseFloat(value ?? "0")}
            />
          </Form.Item>
          <Form.Item
            name="sharedMemory"
            rules={[
              { required: true },
              numberMinRule(
                0,
                "Shared Memory must be greater than or equal to 0"
              ),
            ]}
            noStyle
          >
            <InputNumber
              style={{ width: "100%" }}
              suffix={"GiB"}
              min={0}
              addonBefore={
                <InstanceInputAddon>Shared Memory</InstanceInputAddon>
              }
            />
          </Form.Item>
        </Space.Compact>
      </Form.Item>
      <Form.Item
        label="AI Accelerator"
        required={false}
        style={{ marginBottom: 0 }}
      >
        <Flex direction="row" align="start" gap={"xs"}>
          <Form.Item name="acceleratorType">
            <Select
              style={{ width: "256px" }}
              options={_(resourceSlots)
                .map((slot: string) => {
                  const firstSepIdx = slot.indexOf(".");
                  const brand = slot.substring(0, firstSepIdx); // "cuda"
                  const unit = slot.substring(firstSepIdx); // "device:1g.5gb-mig"
                  return {
                    value: slot,
                    label:
                      (RESOURCE_SLOT_LABEL_DEFS[brand] ?? brand.toUpperCase()) +
                      (unit === "shares" ? " (fractional)" : "") +
                      (unit.endsWith("-mig") ? " (MIG)" : ""),
                  };
                })
                .value()}
              onChange={(value) => {
                form.setFieldValue(["slots", value], "0");
              }}
            />
          </Form.Item>
          <Form.Item
            shouldUpdate={(prevValues, nextValues) =>
              prevValues.acceleratorType !== nextValues.acceleratorType
            }
          >
            {({ getFieldValue }) => (
              <Form.Item
                name={["slots", getFieldValue("acceleratorType")]}
                rules={[
                  numberMinRule(
                    parseFloat(
                      systemRequirements[getFieldValue("acceleratorType")]
                    ),
                    `Accelerator must be greater than or equal to ${
                      systemRequirements[getFieldValue("acceleratorType")]
                    }`
                  ),
                ]}
                noStyle
              >
                <InputNumber
                  style={{ width: "100%" }}
                  suffix={
                    resourceSlotsDetails[
                      getFieldValue("acceleratorType") as ResourceSlot
                    ]?.display_unit ?? "Unit"
                  }
                  min={0}
                  step={
                    getFieldValue("acceleratorType")?.endsWith(".shares")
                      ? 0.1
                      : 1
                  }
                />
              </Form.Item>
            )}
          </Form.Item>
        </Flex>
      </Form.Item>
    </>
  );
};

const InstanceInputAddon: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  return <div style={{ width: "105px", textAlign: "right" }}>{children}</div>;
};

export default ResourcesInputItems;
